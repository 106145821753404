import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/store/modules/auth'
import account from '@/store/modules/account'
import users from '@/store/modules/users'
import comments from '@/store/modules/comments'
import documents from '@/store/modules/documents'
import titles from '@/store/modules/titles'
import sections from '@/store/modules/sections'
import resources from '@/store/modules/resources'
import versions from '@/store/modules/versions'
import access from '@/store/modules/access'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ['auth']
    })
  ],
  modules: {
    auth,
    account,
    users,
    documents,
    comments,
    titles,
    sections,
    resources,
    versions,
    access
  },
  strict: debug
})
