<template>
  <div class="export-manager">
    <div class="menu-bar">
      <b-button size="sm" @click="showExportForm()" class="export-button">
        <b-icon-printer /> Tulosta ja julkaise
      </b-button>
      <b-button  size="sm" @click="showVersionList()" class="versions-button">
        <b-icon-list /> Versiot
      </b-button>
    </div>
    <!--Modal for downloading or printing pdf-->
    <b-modal size=md :id="exportForm.id" :title="exportForm.title" scrollable @hide="resetExportForm">
      <b-tabs content-class="mt-3">
        <b-tab title="Kansilehti">
          <b-container fluid >
            <b-form>
              <b-form-group id:="input-group-cover-date" label="Päivämäärä" label-for="input-cover-date">
                <b-form-input
                id="input-cover-date"
                type="date"
                v-model="coverDate"></b-form-input>
              </b-form-group>

              <b-form-group id:="input-group-cover-description" label="Tarkenne" label-for="input-cover-description">
                <b-form-input
                id="input-cover-description"
                type="text"
                maxlength="100"
                v-model="coverDescription"
                placeholder="Kuvaus"></b-form-input>
              </b-form-group>
            </b-form>
          </b-container>
        </b-tab>

        <b-tab title="Sisältö">
          <b-card-text>
              <TreePanel v-model="selected" show-checkbox multiple allow-batch/>
        </b-card-text>
        </b-tab>

        <b-tab title="Julkaise">
          <b-container fluid >
            <b-form>
              <b-form-checkbox
                id="version-checkbox"
                v-model="saveVersion"
                name="save-version">
                Haluan myös tallena versio
              </b-form-checkbox>
              <div class="text-field">
                <b-form-group id:="input-group-version-name" label="Version nimi" label-for="input-name">
                  <b-form-input
                    id="input-version-name"
                    type="text"
                    v-model="version.name"
                    :state="fieldState('version', 'name')"
                    :disabled="!saveVersion"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-version-name-feedback"
                  >{{ errors['version']['name'] }}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id:="input-group-version-description" label="Version kuvaus" label-for="input-description">
                  <b-form-input
                    id="input-version-description"
                    type="text"
                    v-model="version.description"
                    :state="fieldState('version', 'description')"
                    :disabled="!saveVersion"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-version-description-feedback"
                  >{{ errors['version']['description'] }}</b-form-invalid-feedback>
                </b-form-group>
              </div>
            </b-form>
          </b-container>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button v-if="saveVersion" :disabled="loading" class="hilkka-style" size="sm" @click="saveVersionAndExportDocument()">
          <b-icon-printer-fill v-show="!loading"/>
          <b-spinner small v-show="loading"></b-spinner>
          Tulosta ja julkaise versioksi
        </b-button>
        <b-button v-else :disabled="loading" class="mr-1" size="sm" @click="exportDocument()">
          <b-icon-printer-fill  v-show="!loading" />
          <b-spinner small v-show="loading"></b-spinner>
          Tulosta
        </b-button>
      </template>
    </b-modal>
    <!--Modal for showing the versions-->
    <b-modal size=lg :id="versionList.id" :title="versionList.title" ok-only @hide="resetVersionList">
      <b-container fluid>
          <b-table
            striped hover
            :items="versionItems"
            :fields="versionFields">
            <template #cell(actions)="row">
              <div align="right">
                <b-button v-b-tooltip.hover title="Tulosta" size="sm" class="mr-1" @click="downloadVersion(row.item.id)">
                  <b-icon-printer-fill/>
                </b-button>
                <b-button v-b-tooltip.hover title="Poista" size="sm" class="mr-1" variant="danger" @click="deleteVersion(row.item)">
                  <b-icon-trash/>
                </b-button>
              </div>
            </template>
          </b-table>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

import TreePanel from '@/components/document/TreePanel.vue';

export default {
  name: 'ExportManager',
  data() {
    return {
      exportForm: {
        id: 'export-form',
        title: 'Tulosta ja julkaise',
      },
      errors: { version: {} },
      coverDate: '',
      coverDescription: '',
      version: { name: '', description: '' },
      selected: [],
      saveVersion: false,
      loading: false,
      versionFields: [
        { key: 'name', label: 'Versio'},
        { key: 'description', label: 'Kuvaus'},
        { key: 'createdBy', label: 'Tekijä'},
        { key: 'createdAt', label: 'Päivämäärä'},
        { key: 'actions', label: ''}
      ],
      versionList: {
        id: 'version-list-modal',
        title: 'Versiot',
      }
    }
  },
  computed: {
    versionItems () {
      return this.$store.state.versions.all
    }
  },
  components: {
    TreePanel
  },

  methods: {
    openPdf(data) {
      var blob = new Blob([data], {type: 'application/pdf'});
      window.open(URL.createObjectURL(blob));
    },
    async exportDocument() {
      this.loading = true;
      const payload = {
        id: this.$route.params.documentId,
        titleIds: this.selected,
        date: this.coverDate,
        description: this.coverDescription
      }
      const pdf = await this.$store.dispatch('documents/export', payload);
      this.openPdf(pdf);
      this.loading = false;
      this.$bvModal.hide(this.exportForm.id);
    },
    async saveVersionAndExportDocument() {
      this.loading = true;
      const payload = {
        id: this.$route.params.documentId,
        titleIds: this.selected,
        date: this.coverDate,
        description: this.coverDescription,
        version: this.version
      };
      try {
        const versionId = await this.$store.dispatch('documents/exportVersion', payload);
        this.downloadVersion(versionId);
        this.$bvModal.hide(this.exportForm.id);
        this.$store.dispatch('versions/loadAll', this.$route.params.documentId);
      } catch (error) {
          this.handleFormError(error);
      }
      this.loading = false;
    },
    async downloadVersion(versionId) {
      this.loading = true;
      const payload = {
        documentId: this.$route.params.documentId,
        versionId: versionId
      }
      const pdf = await this.$store.dispatch('versions/download', payload);
      this.openPdf(pdf);
      this.loading = false;
    },
    async deleteVersion(version) {
      const options = {
        title: 'Vahvistus',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Poista',
        cancelTitle: 'Peruuta',
      };
      const confirmed = await this.$bvModal.msgBoxConfirm(`Haluatko varmasti poista versio "${version.name}"?`, options);
      if (confirmed) {
        const payload = {
          documentId: this.$route.params.documentId,
          versionId: version.id,
        };
        await this.$store.dispatch('versions/delete', payload);
        this.$store.dispatch('versions/loadAll', this.$route.params.documentId);
      }
    },

    fieldState(key, field) {
      return this.errors[key] === undefined || this.errors[key][field] === undefined ? null : false;
    },
    handleError(error) {
      this.$bvModal.msgBoxOk(`The request failed with status ${error.response.status}.`);
    },
    handleFormError(error) {
      if (error.response.status == 422) {
        this.$bvModal.show(this.exportForm.id);
        this.errors = error.response.data.data;
      } else {
        this.handleError(error);
      }
    },

    showExportForm() {
      this.$bvModal.show(this.exportForm.id);
    },
    resetExportForm() {
      this.errors = { version: {} };
      this.loading = false;
      this.selected = [];
      this.coverDate = '';
      this.coverDescription = '';
      this.saveVersion = false;
      this.version = { name: null, description: null };
    },
    showVersionList() {
      this.$bvModal.show(this.versionList.id);
    },
    resetVersionList() {},
  }
}
</script>

<style>
.text-field {
  margin-top: 20px;
}
.export-button {
  margin-left: 10px;
  margin-right: 7px;
}

/* #export-button {
  margin-left: 15px;
} */
</style>
