<template>
  <div class="password-token-form">
    <b-container fluid="sm">
      <b-row class="vh-100" align-v="center">
        <b-col>
          <div v-if="errors.token">
            <b-alert show variant="danger">
              <h3 align="center" class="alert-heading">{{ errors.token }}</h3>
            </b-alert>
          </div>
          <b-form v-else>
            <b-form-group
              id="input-group-email"
              label="Email address"
              label-for="input-email">
              <b-form-input
                id="input-email"
                :value="item.email"
                type="email"
                name="email"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-password"
              :label="this.setup ? 'Password' : 'New password'"
              label-for="input-password">
              <b-form-input
                id="input-password"
                v-model="item.password"
                :state="fieldState(errors.password)"
                type="password"
                name="password"
                minlength="16"
                required
                :placeholder="this.setup ? 'Enter a password' : 'Enter new password'"
              ></b-form-input>
              <b-form-invalid-feedback id="input-password-feedback">{{ errors.password }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-confirm" label="Confirm password:" label-for="input-password">
              <b-form-input
                id="input-confirm"
                v-model="item.confirm"
                :state="fieldState(errors.confirm)"
                type="password"
                name="confirm"
                minlength="16"
                required
                placeholder="Enter the password again"
              ></b-form-input>
              <b-form-invalid-feedback id="input-password-feedback">{{ errors.confirm }}</b-form-invalid-feedback>
            </b-form-group>

            <b-button size="sm" @click="submitResetForm()" variant="primary">Save</b-button>

          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'PasswordTokenForm',
  props: {
    setup: Boolean
  },
  data() {
    return {
      item: {},
      errors: {},
    }
  },
  async mounted() {
    this.item = { email: null, password: null, confirm: null };
    try {
      this.item.email = await this.$store.dispatch('auth/fetchEmailByResetToken', this.$route.params.token);
    } catch (error) {
      this.errors = { token: 'Error:' + error.response.data.data };
    }
  },
  methods: {
    fieldState(field) {
      return field === undefined ? null : false;
    },
    handleError(error) {
      this.$bvModal.msgBoxOk(`The request failed with status ${error.response.data.statusCode}.`);
    },
    handleFormError(error) {
      if (error.response.data.statusCode == 422) {
        this.errors = error.response.data.data;
      } else {
        this.handleError(error);
      }
    },

    async submitResetForm() {
      if (this.item.password != this.item.confirm) {
        this.errors = { password: 'The passwords do not match', confirm: 'The passwords do not match' };
      } else {
        try {
          await this.$store.dispatch('auth/resetPassword', {
            resetToken: this.$route.params.token,
            password: this.item.password
          });
          await this.$store.dispatch('auth/login', {
            email: this.item.email,
            password: this.item.password
          });
          this.$router.push('/');
        } catch (error) {
          this.handleFormError(error);
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
