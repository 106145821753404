<template>
  <div class="document-form">
    <b-modal :id="form.id" :title="form.title" ok-cancel @ok="submitDocument">
      <b-container fluid>
        <b-form>
          <b-form-group id="input-group-name" label="Hankkeen nimi:" label-for="input-name">
            <b-form-input
              id="input-name"
              v-model="item.name"
              :state="fieldState('name')"
              placeholder="Hankkeen nimi"
              type="text"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback">{{ errors['name'] }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-number" label="Selostusnumero:" label-for="input-number">
            <b-form-input
              id="input-externalId"
              v-model="item.externalId"
              :state="fieldState('externalId')"
              placeholder="Selostusnumero"
              maxlength="40"
              type="text"
            ></b-form-input>
            <b-form-invalid-feedback id="input-externalId-feedback">{{ errors['externalId'] }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="input-group-description" label="Kuvaus:" label-for="input-description">
              <b-form-input
                id="input-description"
                v-model="item.description"
                :state="fieldState('description')"
                placeholder="Kuvaus"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback id="input-description-feedback">{{ errors['description'] }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-client" label="Tilaaja:" label-for="input-client">
              <b-form-input
                id="input-client"
                v-model="item.client"
                :state="fieldState('client')"
                placeholder="Tilaaja"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback id="input-client-feedback">{{ errors['client'] }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="document-image" label="Kuva:" label-for="document-image">
              <img class="img-fluid" style="" :src="item.photo" alt="">
              <input id="document-image" type="file" @change="handleImage" accept="image/*" class="input-file">
            </b-form-group>
            <b-form-group id="input-group-language" label="Kieli:" label-for="input-language">
              <b-form-radio-group
                id="input-language"
                v-model="item.language"
                :options="languages"
                :state="fieldState('language')"
              ></b-form-radio-group>
              <b-form-invalid-feedback id="input-language-feedback">{{ errors['language'] }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="input-group-status" label="Status:" label-for="input-status">
              <b-form-radio-group
                id="input-status"
                v-model="item.status"
                :options="statusOptions"
                :state="fieldState('status')"
              ></b-form-radio-group>
              <b-form-invalid-feedback id="input-status-feedback">{{ errors['status'] }}</b-form-invalid-feedback>
            </b-form-group>
          </b-form>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'DocumentForm',
  data() {
     return {
       errors: {},
       item: {},
       copy: false,
       form: {
         id: 'document-form-modal',
         title: ''
       },
       statusOptions: [{ value: 'WIP', text: 'Työn alla' }, { value: 'DONE', text: 'Valmis'}],
       languages: [{ value: 'FI', text: 'Suomi' }, { value: 'SV', text: 'Svenska' }],
     }
  },
  mounted () {
    this.form.id = this.form.id + '_' + this._uid
  },
  methods: {
    async showCreateForm() {
      this.form.title = 'Hankkeen tiedot';
      this.item = {
        name: null,
        description: null,
        photo: null,
        externalId: null,
        language: 'FI',
        status: 'WIP',
        client: null
      };
      this.errors = {};
      this.$bvModal.show(this.form.id);
    },

    async showCopyForm(item) {
      this.form.title = 'Hankkeen tiedot';
      this.copy = true;
      this.item = await this.$store.dispatch('documents/fetch', item.id);
      this.item.name = `Kopio ${this.item.name}`;
      this.errors = {};
      this.$bvModal.show(this.form.id);
    },

    async showUpdateForm(item) {
      this.form.title = 'Muokkaa tietoja';
      this.copy = false;
      this.item = await this.$store.dispatch('documents/fetch', item.id);
      this.errors = {};
      this.$bvModal.show(this.form.id);
    },

    async submitDocument() {
      try {
        if (this.item.id) {
          if (this.copy) {
            await this.$store.dispatch('documents/copy', this.item);
          } else {
            await this.$store.dispatch('documents/update', this.item);
          }
        } else {
          await this.$store.dispatch('documents/create', this.item);
        }
      } catch (error) {
        this.handleFormError(error);
      }
    },

    fieldState(field) {
      return this.errors[field] === undefined ? null : false;
    },

    handleError(error) {
      this.$bvModal.msgBoxOk(`The request failed with status ${error.response.data.statusCode}.`);
    },

    handleFormError(error) {
      if (error.response.data.statusCode == 422) {
        this.$bvModal.show(this.form.id);
        this.errors = error.response.data.data;
      } else {
        this.handleError(error);
      }
    },

    handleImage(e) {
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.item.photo = e.target.result;
      };
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#document-image{
  margin-top: 10px;

}

</style>
