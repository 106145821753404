const state = () => ({
  all: [],
  active: {},
})

const mutations = {
  setAll (state, all) {
    state.all = all;
  },
  setActive (state, active) {
    state.active = active;
  },
}

const actions = {
  async load (context) {
    context.commit('setAll', await context.dispatch('fetchAll'));
  },
  async setActive (context, documentId) {
    context.commit('setActive', await context.dispatch('fetch', documentId));
  },
  async clearActive (context) {
    context.commit('setActive', {});
  },

  async fetchAll () {
    const response = await this._vm.$axios.get(`documents`);
    return response.data.data;
  },
  async fetch(context, id) {
    const response = await this._vm.$axios.get(`documents/${id}?fields=id,name,description,client,language,status,externalId,photo`);
    return response.data.data;
  },
  async create(context, document) {
    await this._vm.$axios.post(`documents`, document);
    await context.dispatch('load');
  },
  async update(context, document) {
    const { id, ...values } = document;
    await this._vm.$axios.patch(`documents/${id}`, values);
    await context.dispatch('load');
    if (Object.keys(context.state.active).length != 0 && context.state.active.id == id) {
      context.dispatch('setActive', context.state.active.id);
    }
  },
  async copy(context, document) {
    const { id, ...values } = document;
    await this._vm.$axios.post(`documents/${id}`, values);
    await context.dispatch('load');
  },
  async delete(context, id) {
    await this._vm.$axios.delete(`documents/${id}`);
    await context.dispatch('load');
  },
  async export(context, { id, titleIds, date, description }) {
    const titles = titleIds.join(',');
    const response = await this._vm.$axios.get(
      `documents/${id}/export?titleIds=${titles}&date=${date}&description=${description}`,
      { responseType: 'arraybuffer' }
    );
    return response.data;
  },
  async exportVersion(context, { id, titleIds, date, description, version }) {
    const response = await this._vm.$axios.post(
      `documents/${id}/export`,
      { titleIds: titleIds.join(','), date: date, description: description, version: version }
    );
    return response.headers.location.split('/').reverse()[0];
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
