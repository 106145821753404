import Vue from 'vue'
import VueRouter from 'vue-router'

import DocumentList from '@/views/DocumentList'
import Document from '@/views/Document'
import Login from '@/views/Login'
import ResetPassword from '@/views/ResetPassword'
import SetupPassword from '@/views/SetupPassword'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/document',
    name: 'DocumentList',
    component: DocumentList
  },
  {
    path: '/document/:documentId/:titleId',
    name: 'Document',
    component: Document
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/setup/:token',
    name: 'SetupPassword',
    component: SetupPassword
  },
  {
    path: '*',
    redirect: '/document'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (store.state.auth.isLoggedIn == false
    && to.name !== 'Login' && to.name !== 'ResetPassword' && to.name !== 'SetupPassword') {
    next({
      path: '/login',
      replace: true
    })
  } else {
    if (to.name !== 'Login' && to.name !== 'ResetPassword' && to.name !== 'SetupPassword') {
      store.dispatch('account/load');
    }
    next();
  }
})

export default router
