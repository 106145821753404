import ax from 'axios';

let instance = ax.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BASE_URL + '/rest/',
});

export const axios = instance;

export default {
  install (Vue) {
    Vue.prototype.$axios = instance;
  }
}
