<template>
  <span class="section-manager">
    <!--Modal for renaming section-->
    <b-modal :id="rename.id" :title="rename.title" ok-cancel @ok="saveSection()">
      <b-form-group id="input-new-name" label="Nimeä uudelleen: ">
        <b-form-input v-model="rename.text"></b-form-input>
      </b-form-group>
    </b-modal>

    <div class="menu-bar clear-fix">
      <b-row>
        <b-col class="menu-bar-left" cols="8">
          <span class="hilkka-style">
            <b-button size="sm" class="preview menu-button" :pressed.sync="preview"><b-icon-eye /> Esikatselu</b-button>
            <b-button :disabled="preview" size="sm" class="comment-button menu-button" @click="addComment(comment)"><b-icon-plus /> Lisää kommentti</b-button>
            <b-dropdown :disabled="preview" size="sm" class="section-content-add menu-button">
              <template #button-content>
                <b-icon-plus /> Lisää tieto
              </template>
              <b-dropdown-item :disabled="contents.commons && contents.commons.length != 0" @click="addSection('COMMON', 'Yleistä')">Yleistä</b-dropdown-item>
              <b-dropdown-item v-if="document.language == 'SV'" @click="addSection('PART', 'Byggnadsdel')">Rakennusosa</b-dropdown-item>
              <b-dropdown-item v-else @click="addSection('PART', 'Rakennusosa')">Rakennusosa</b-dropdown-item>
            </b-dropdown>
          </span>
        </b-col>
        <b-col  class="menu-bar-right" cols="4">
          <span class="title-status-group">
            <b-button class="title-status title-status-missing px-2" :pressed="false" @click="updateStatus('MISSING')"></b-button>
            <b-button class="title-status title-status-partial px-2" :pressed="false" @click="updateStatus('PARTIAL')"></b-button>
            <b-button class="title-status title-status-complete px-2" :pressed="true" @click="updateStatus('COMPLETE')"></b-button>
          </span>
        </b-col>
      </b-row>
    </div>
    <div class="content" id="content">
      <div v-show="!preview" class="comments">
        <div class="comment" v-for="(comment) in comments" :key="comment.id">
          <div class="clearfix">
            <span v-if="!hasUnsavedComment(comment.id)">
              <div class="comment-title">
                {{comment.createdBy}}
                {{comment.createdAt}}
              </div>
              {{comment.message}}
              <div class="edit-comment-button-group float-right">
                <b-button size="sm" class="edit-comment-button" @click="editComment(comment)"> <b-icon-pencil/></b-button>
                <b-button size="sm" class="delete-comment-button" @click="deleteComment(comment)"> <b-icon-trash/></b-button>
              </div>
            </span>
            <span class="textarea-box" v-if="hasUnsavedComment(comment.id)">
              <b-form-textarea
                id="textarea"
                v-model="unsavedComment[comment.id]"
                placeholder="Lisää kommentti..."
                rows="1"
                max-rows="6"
                no-resize
              ></b-form-textarea>
              <span class="comment-button-group float-right">
                <b-button size="sm" class="cancel-comment-button" @click="cancelComment(comment)">Peruuta</b-button>
                <b-button size="sm" class="save-comment-button" @click="saveComment(comment)">Tallenna</b-button>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="clearfix">
        <div class="title float-left">{{getTitle()}}</div>
        <b-dropdown v-if="contents.commons && contents.commons.length != 0" v-show="!preview" :no-caret="true" size="sm" class="common-menu float-right" variant="primary">
          <template #button-content>
            <b-icon-list/>
          </template>
          <b-dropdown-item @click="addContent(contents.commons[0], 'GENERAL')"><b-icon-plus/> Lisää yleistä</b-dropdown-item>
          <b-dropdown-item @click="addContent(contents.commons[0], 'REQUIREMENT')"><b-icon-plus/> Lisää laatuvaatimukset</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="deleteSection(contents.commons[0])"><b-icon-trash/> Poista</b-dropdown-item>
        </b-dropdown>
      </div>
      <b-spinner v-if="contentsLoading && contents.length == 0" class="m-3" label="Busy" small></b-spinner>
      <div :class="'sections ' + groupIdx" v-else v-for="(group, groupIdx) in contents" :key="groupIdx">
        <div class="section" v-for="(section, sectionIdx) in group" :key="section.id">
          <div class="clearfix" v-if="section.type != 'COMMON'">
            <div class="section-title float-left">{{section.name}}</div>
            <b-dropdown v-show="!preview" :no-caret="true" size="sm" class="section-menu float-right" variant="primary">
              <template #button-content>
                <b-icon-list/>
              </template>
              <b-dropdown-item @click="addContent(section, 'DESCRIPTION')"><b-icon-plus/> Lisää kuvaus</b-dropdown-item>
              <b-dropdown-item @click="addContent(section, 'REQUIREMENT')"><b-icon-plus/> Lisää laatuvaatimukset</b-dropdown-item>
              <b-dropdown-item @click="addContent(section, 'INSTRUCTION')"><b-icon-plus/> Lisää työohjeet</b-dropdown-item>
              <b-dropdown-item @click="addContent(section, 'FEATURE')"><b-icon-plus/> Lisää ominaisuudet</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="moveUpSection(section)" v-if="sectionIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
              <b-dropdown-item @click="moveDownSection(section)" v-if="sectionIdx < group.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
              <b-dropdown-item @click="editSection(section)"><b-icon-pencil/> Nimeä uudelleen</b-dropdown-item>
              <b-dropdown-item @click="deleteSection(section)"><b-icon-trash/> Poista</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="section-contents generals" v-if="section.generals">
            <div class="section-content general" v-for="(general, generalIdx) in section.generals" :key="general.id">
              <b-dropdown class="section-content-menu" v-show="!preview" v-if="!hasUnsavedContent(general.id)" :no-caret="true" size="sm">
                <template #button-content>
                  <b-icon-list/>
                </template>
                <b-dropdown-item @click="moveUpContent(section, 'GENERAL', general)" v-if="generalIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
                <b-dropdown-item @click="moveDownContent(section, 'GENERAL', general)" v-if="generalIdx < section.generals.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
                <b-dropdown-item @click="editContent(section, 'GENERAL', general)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
                <b-dropdown-item @click="deleteContent(section, 'GENERAL', general)"><b-icon-trash/> Poista</b-dropdown-item>
              </b-dropdown>
              <div class="section-content-body" v-show="!hasUnsavedContent(general.id)" v-html="general.data"></div>
              <div class="section-content-body" v-if="hasUnsavedContent(general.id)" v-show="preview" v-html="unsaved[general.id]"></div>
              <Editor
                class="section-content-editor"
                v-if="hasUnsavedContent(general.id)"
                v-show="!preview"
                v-model="unsaved[general.id]"
                v-on:save="saveContent(section, 'GENERAL', general)"
                v-on:cancel="cancelContent(section, 'GENERAL', general)" />
            </div>
          </div>
          <div class="section-contents descriptions" v-if="section.descriptions">
            <div class="section-content description" v-for="(description, descriptionIdx) in section.descriptions" :key="description.id">
              <b-dropdown class="section-content-menu" v-show="!preview" v-if="!hasUnsavedContent(description.id)" :no-caret="true" size="sm">
                <template #button-content>
                  <b-icon-list/>
                </template>
                <b-dropdown-item @click="moveUpContent(section, 'DESCRIPTION', description)" v-if="descriptionIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
                <b-dropdown-item @click="moveDownContent(section, 'DESCRIPTION', description)" v-if="descriptionIdx < section.descriptions.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
                <b-dropdown-item @click="editContent(section, 'DESCRIPTION', description)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
                <b-dropdown-item @click="deleteContent(section, 'DESCRIPTION', description)"><b-icon-trash/> Poista</b-dropdown-item>
              </b-dropdown>
              <div class="section-content-body" v-show="!hasUnsavedContent(description.id)" v-html="description.data"></div>
              <div class="section-content-body" v-if="hasUnsavedContent(description.id)" v-show="preview" v-html="unsaved[description.id]"></div>
              <Editor
                class="section-content-editor"
                v-if="hasUnsavedContent(description.id)"
                v-show="!preview"
                v-model="unsaved[description.id]"
                v-on:save="saveContent(section, 'DESCRIPTION', description)"
                v-on:cancel="cancelContent(section, 'DESCRIPTION', description)" />
            </div>
          </div>
          <div class="section-contents requirements" v-if="section.requirements">
            <div v-if="document.language == 'SV'" class="section-content-title">Kvalitetskrav</div>
            <div v-else class="section-content-title">Laatuvaatimukset</div>
            <div class="section-content requirement" v-for="(requirement, requirementIdx) in section.requirements" :key="requirement.id">
              <b-dropdown class="section-content-menu" v-show="!preview" v-if="!hasUnsavedContent(requirement.id)" :no-caret="true" size="sm">
                <template #button-content>
                  <b-icon-list/>
                </template>
                <b-dropdown-item @click="moveUpContent(section, 'REQUIREMENT', requirement)" v-if="requirementIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
                <b-dropdown-item @click="moveDownContent(section, 'REQUIREMENT', requirement)" v-if="requirementIdx < section.requirements.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
                <b-dropdown-item @click="editContent(section, 'REQUIREMENT', requirement)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
                <b-dropdown-item @click="deleteContent(section, 'REQUIREMENT', requirement)"><b-icon-trash/> Poista</b-dropdown-item>
              </b-dropdown>
              <div class="section-content-body" v-show="!hasUnsavedContent(requirement.id)"  v-html="requirement.data"></div>
              <div class="section-content-body" v-if="hasUnsavedContent(requirement.id)" v-show="preview" v-html="unsaved[requirement.id]"></div>
              <Editor
                class="section-content-editor"
                v-if="hasUnsavedContent(requirement.id)"
                v-show="!preview"
                v-model="unsaved[requirement.id]"
                v-on:save="saveContent(section, 'REQUIREMENT', requirement)"
                v-on:cancel="cancelContent(section, 'REQUIREMENT', requirement)"  />
            </div>
          </div>
          <div class="section-contents instructions" v-if="section.instructions">
            <div v-if="document.language == 'SV'" class="section-content-title">Arbetsinstruktioner</div>
            <div v-else class="section-content-title">Työohjeet</div>
            <div class="section-content instruction" v-for="(instruction, instructionIdx) in section.instructions" :key="instruction.id">
              <b-dropdown class="section-content-menu" v-show="!preview" v-if="!hasUnsavedContent(instruction.id)" :no-caret="true" size="sm">
                <template #button-content>
                  <b-icon-list/>
                </template>
                <b-dropdown-item @click="moveUpContent(section, 'INSTRUCTION', instruction)" v-if="instructionIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
                <b-dropdown-item @click="moveDownContent(section, 'INSTRUCTION', instruction)" v-if="instructionIdx < section.instructions.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
                <b-dropdown-item @click="editContent(section, 'INSTRUCTION', instruction)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
                <b-dropdown-item @click="deleteContent(section, 'INSTRUCTION', instruction)"><b-icon-trash/> Poista</b-dropdown-item>
              </b-dropdown>
              <div class="section-content-body" v-show="!hasUnsavedContent(instruction.id)" v-html="instruction.data"></div>
              <div class="section-content-body" v-if="hasUnsavedContent(instruction.id)" v-show="preview" v-html="unsaved[instruction.id]"></div>
              <Editor
                class="section-content-editor"
                v-if="hasUnsavedContent(instruction.id)"
                v-show="!preview"
                v-model="unsaved[instruction.id]"
                v-on:save="saveContent(section, 'INSTRUCTION', instruction)"
                v-on:cancel="cancelContent(section, 'INSTRUCTION', instruction)" />
            </div>
          </div>
          <div class="section-contents features" v-if="section.features">
            <div v-if="document.language == 'SV'" class="section-content-title">Egenskaper</div>
            <div v-else class="section-content-title">Ominaisuudet</div>
            <div class="section-content feature" v-for="(feature, featureIdx) in section.features" :key="feature.id">
              <b-dropdown class="section-content-menu" v-show="!preview" v-if="!hasUnsavedContent(feature.id)" :no-caret="true" size="sm">
                <template #button-content>
                  <b-icon-list/>
                </template>
                <b-dropdown-item @click="moveUpContent(section, 'FEATURE', feature)" v-if="featureIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
                <b-dropdown-item @click="moveDownContent(section, 'FEATURE', feature)" v-if="featureIdx < section.features.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
                <b-dropdown-item @click="editContent(section, 'FEATURE', feature)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
                <b-dropdown-item @click="deleteContent(section, 'FEATURE', feature)"><b-icon-trash/> Poista</b-dropdown-item>
              </b-dropdown>
              <div class="section-content-body" v-show="!hasUnsavedContent(feature.id)" v-html="feature.data"></div>
              <div class="section-content-body" v-if="hasUnsavedContent(feature.id)" v-show="preview" v-html="unsaved[feature.id]"></div>
              <Editor
                class="section-content-editor"
                v-if="hasUnsavedContent(feature.id)"
                v-show="!preview"
                v-model="unsaved[feature.id]"
                v-on:save="saveContent(section, 'FEATURE', feature)"
                v-on:cancel="cancelContent(section, 'FEATURE', feature)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import Editor from "@/components/editor/Editor";

export default {
  name: 'SectionManager',
  data() {
    return {
      preview: false,
      unsaved: [],
      unsavedComment: [],
      comment: '',
      rename: {
        id: 'rename-modal',
        title: '',
        section: {},
        text: '',
      }
    }
  },
  computed: {
    document() {
      return this.$store.state.documents.active
    },

    contents () {
      return this.$store.state.sections.all
    },
    contentsLoading () {
      return this.$store.state.sections.loading
    },

    comments() {
      return this.$store.state.comments.all
    },
    commentsLoading() {
      return this.$store.state.comments.loading
    }
  },
  methods: {
    hasUnsavedContent(id) {
      return this.unsaved[id] !== undefined;
    },
    hasUnsavedComment(id) {
      return this.unsavedComment[id] !== undefined;
    },
    async addContent(section, type) {
      const payload = {
        documentId: this.$route.params.documentId,
        titleId: this.$route.params.titleId,
        sectionId: section.id,
        type: type,
        content: { data: null }
      }
      const contentId = await this.$store.dispatch('sections/createContent', payload);
      this.$set(this.unsaved, contentId, '');
      this.$store.dispatch('sections/loadAll', this.$route.params);
    },
    editContent(section, type, content) {
      this.$set(this.unsaved, content.id, content.data);
    },
    async saveContent(section, type, content) {
      try {
        const payload = {
          documentId: this.$route.params.documentId,
          titleId: this.$route.params.titleId,
          sectionId: section.id,
          type: type,
          content: { id: content.id, data: this.unsaved[content.id] }
        };
        if (content.id) {
          await this.$store.dispatch('sections/updateContent', payload);
        } else {
          await this.$store.dispatch('sections/createContent', payload);
        }
        this.$set(this.unsaved, content.id);
        this.$store.dispatch('sections/loadAll', this.$route.params);
      } catch (error) {
        this.handleFormError(error);
      }
    },
    cancelContent(section, type, content) {
      this.$set(this.unsaved, content.id);
    },
    async moveUpContent(section, type, content) {
      try {
        const payload = {
          documentId: this.$route.params.documentId,
          titleId: this.$route.params.titleId,
          sectionId: section.id,
          type: type,
          contentId: content.id
        };
        await this.$store.dispatch('sections/moveUpContent', payload);
        this.$store.dispatch('sections/loadAll', this.$route.params);
      } catch (error) {
        this.handleError(error);
      }
    },
    async moveDownContent(section, type, content) {
      try {
        const payload = {
          documentId: this.$route.params.documentId,
          titleId: this.$route.params.titleId,
          sectionId: section.id,
          type: type,
          contentId: content.id
        };
        await this.$store.dispatch('sections/moveDownContent', payload);
        this.$store.dispatch('sections/loadAll', this.$route.params);
      } catch (error) {
        this.handleError(error);
      }
    },
    async deleteContent(section, type, content) {
      const options = {
        title: 'Vahvistus',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Poista',
        cancelTitle: 'Peruuta',
      };
      const confirmed = await this.$bvModal.msgBoxConfirm(`Haluatko poistaa sisällön?`, options);
      if (confirmed) {
        try {
          if (this.unsaved[content.id]) {
            this.$set(this.unsaved, content.id);
          }
          const payload = {
            documentId: this.$route.params.documentId,
            titleId: this.$route.params.titleId,
            sectionId: section.id,
            type: type,
            contentId: content.id
          };
          await this.$store.dispatch('sections/deleteContent', payload);
          this.$store.dispatch('sections/loadAll', this.$route.params);
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    async addSection(type, title) {
      const payload = {
        documentId: this.$route.params.documentId,
        titleId: this.$route.params.titleId,
        section: { type: type, name: title }
      }
      await this.$store.dispatch('sections/create', payload);
      this.$store.dispatch('sections/loadAll', this.$route.params);
    },
    async deleteSection(section) {
      const options = {
        title: 'Please Confirm',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      };
      const confirmed = await this.$bvModal.msgBoxConfirm(`Haluatko poistaa sisällön?`, options);
      if (confirmed) {
        const payload = {
          documentId: this.$route.params.documentId,
          titleId: this.$route.params.titleId,
          sectionId: section.id,
        };
        await this.$store.dispatch('sections/delete', payload);
        this.$store.dispatch('sections/loadAll', this.$route.params);
      }
    },
    async editSection(section) {
      this.rename.text = section.name;
      this.rename.section = section;
      this.$bvModal.show(this.rename.id);
    },
    async saveSection() {
      const payload = {
        documentId: this.$route.params.documentId,
        titleId: this.$route.params.titleId,
        section: { id: this.rename.section.id, name: this.rename.text }
      };
      await this.$store.dispatch('sections/update', payload)
      this.$store.dispatch('sections/loadAll', this.$route.params);
    },
    async moveUpSection(section) {
      const payload = {
        documentId: this.$route.params.documentId,
        titleId: this.$route.params.titleId,
        sectionId: section.id
      };
      await this.$store.dispatch('sections/moveUp', payload)
      this.$store.dispatch('sections/loadAll', this.$route.params);
    },
    async moveDownSection(section) {
      const payload = {
        documentId: this.$route.params.documentId,
        titleId: this.$route.params.titleId,
        sectionId: section.id
      };
      await this.$store.dispatch('sections/moveDown', payload)
      this.$store.dispatch('sections/loadAll', this.$route.params);
    },

    async updateStatus(status) {
      const payload = {
        documentId: this.$route.params.documentId,
        titleId: this.$route.params.titleId,
        status: status,
      };
      await this.$store.dispatch('titles/setStatus', payload)
      this.$store.dispatch('titles/load', this.$route.params.documentId);
    },

    handleError(error) {
      this.$bvModal.msgBoxOk(`The request failed with status ${error.response.data.statusCode}.`);
    },
    handleFormError(error) {
      if (error.response.data.statusCode == 422) {
        this.$bvModal.msgBoxOk("Sisällön enimmäispituus ylitetty (16,7 Mt)");
      } else {
        this.handleError(error);
      }
    },
    getTitle() {
        return this.findTitle(this.$store.state.titles.all);
    },
    findTitle(titles) {
      for (let i = 0; i < titles.length; i++) {
        if (titles[i].id == this.$route.params.titleId) {
          return titles[i].text;
        }
        if (titles[i].children) {
          let title = this.findTitle(titles[i].children);
          if (title) {
            return title;
          }
        }
      }
      return null;
    },
    async addComment () {
      const payload = {
        documentId: this.$route.params.documentId,
        titleId: this.$route.params.titleId,
        comment: { message: ' ' }
      }
      const messageId = await this.$store.dispatch('comments/createComment', payload);
      this.$set(this.unsavedComment, messageId, '');
      this.$store.dispatch('comments/loadAll', this.$route.params);
    },

    cancelComment(comment) {
      this.$set(this.unsavedComment, comment.id);
    },

    async saveComment(comment) {
      try {
        const payload = {
          documentId: this.$route.params.documentId,
          titleId: this.$route.params.titleId,
          comment: { id: comment.id, message: this.unsavedComment[comment.id] }
        };
        await this.$store.dispatch('comments/updateComment', payload);
        this.$set(this.unsavedComment, comment.id);
        this.$store.dispatch('comments/loadAll', this.$route.params);
      } catch (error) {
        this.handleError(error);
      }
    },
    editComment(comment) {
      this.$set(this.unsavedComment, comment.id, comment.message);
    },
    async deleteComment(comment) {
      const options = {
        title: 'Vahvistus',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Poista',
        cancelTitle: 'Peruuta',
      };
      const confirmed = await this.$bvModal.msgBoxConfirm(`Haluatko poistaa kommentin?`, options);
      if (confirmed) {
        try {
          if (this.unsavedComment[comment.id]) {
            this.$set(this.unsavedComment, comment.id);
          }
          const payload = {
            documentId: this.$route.params.documentId,
            titleId: this.$route.params.titleId,
            commentId: comment.id,
          };
          await this.$store.dispatch('comments/delete', payload);
          this.$store.dispatch('comments/loadAll', this.$route.params);
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
  components: {
    Editor
  }
}

</script>

<style scoped>

  .title {
    padding-top: 20px;
    padding-left: 30px;
    font-size: 20px;
    font-weight: bold;
  }

  .common-menu {
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .section-manager {
    font-family: arial;
  }

   .section-content-body {
    padding-top: 2px;
  }

  .section-contents {
    padding-bottom: 10px;
  }

  .section-content {
    font-family: arial;
    padding-left: 50px;
    padding-right: 60px;
    padding-bottom: 5px;
    font-size: 14px;
    position: relative;
    min-height: 45px;
  }

  .section-content-add {
    margin-left:7px;
  }
  .section-content-editor {
    margin-top:5px;
  }
  .content {
    height: calc(100vh - 87px);
    background-color: white;
    overflow: auto;
  }

  .section-content-title {
    font-weight: bold;
    font-size: 14px;
    padding-left: 50px;
    padding-bottom: 7px;
    padding-top: 10px;
  }

  .section-content-menu {
    position: absolute;
    right: 20px;
  }

  .section-title {
    font-weight: bold;
    padding-left: 50px;
    padding-right: 10px;
    padding-top: 14px;
    padding-bottom: 2px;
    font-size: 14px;
  }
  .section-menu {
    padding: 10px 20px;
  }

  .menu-bar {
    text-align: left;
    min-height: 43px;
  }
  .menu-bar-left {
    text-align: left;
    padding: 0 0 6px 9px;
  }
  .menu-bar-left .menu-button {
    margin: 6px 0 0 6px;
  }
  .menu-bar-right {
    min-width: 155px;
    text-align: right;
    padding: 0 0 6px 0;
  }
  .menu-bar-right button {
    margin-top: 12px;
    margin-bottom: 6px;
  }


  .title-status {
    vertical-align: middle;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    border: 0px;
    padding: 8px;
  }
  .title-status-group { margin-right: 10px; }
  .title-status-missing.btn-secondary:not(:disabled):not(.disabled),
  .title-status-missing.btn-secondary:not(:disabled):not(.disabled):active,
  .title-status-missing.btn-secondary:not(:disabled):not(.disabled).active {
    background-color: crimson;
  }
  .title-status-partial.btn-secondary:not(:disabled):not(.disabled),
  .title-status-partial.btn-secondary:not(:disabled):not(.disabled):active,
  .title-status-partial.btn-secondary:not(:disabled):not(.disabled).active {
    background-color: darkorange;
  }
  .title-status-complete.btn-secondary:not(:disabled):not(.disabled),
  .title-status-complete.btn-secondary:not(:disabled):not(.disabled):active,
  .title-status-complete.btn-secondary:not(:disabled):not(.disabled).active {
    background-color: darkgreen;
  }

  #red:active, #red:focus, #red:hover {
    background: #A10F2C;
  }

  #green:active, #green:focus, #green:hover {
    background-color: #004700;
  }

  #orange:active, #orange:focus, #orange:hover {
    background-color: #B56300;
  }

  .add-component{
   margin-right: 10px;
  }

  .section-manager >>> p {
    margin-bottom: 1px;
     min-height: 14px;
  }
  .section-manager >>> blockquote {
    padding-left: 15px !important;
  }
  .section-manager >>> img {
    max-width: 100%;
  }
  .preview {
    margin-right: 7px;
  }

  .comments {
    font-size: 13px;
    background-color: #E9ECE5;
  }

  .comment-title {
    font-weight: bolder;
    color: #606060;
    font-size: 12px;
  }

  .comment {
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    border-style: none none solid none;
    border-color: #F8F8F8;
    border-width: 1px;
  }

  .textarea-box {
    margin: 5px;
  }

  .edit-comment-button-group {
    padding-bottom: 5px;
  }
  .edit-comment-button {
    margin-right: 5px;
  }
  .comment-button-group {
    padding: 5px;
    margin-bottom: 10px;
  }

  .cancel-comment-button {
    margin-right: 5px;
  }


</style>
