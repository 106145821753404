const state = () => ({
  all: []
})

const mutations = {
  setAll (state, all) {
    state.all = all;
  }
}

const actions = {
  async load (context, documentId) {
    context.commit('setAll', await context.dispatch('fetchTitles', documentId));
  },
  async clear (context) {
    context.commit('setAll', []);
  },
  async fetchTitles (context, documentId) {
    const response = await this._vm.$axios.get(`documents/${documentId}/titles`);
    return response.data.data;
  },
  async setStatus(context, { documentId, titleId, status }) {
    await this._vm.$axios.patch(`documents/${documentId}/titles/${titleId}/status`, { status: status });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
