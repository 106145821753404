const state = () => ({
  all: [],
  loading: false
})

const mutations = {
  setAll (state, all) {
    state.all = all;
  },
  setLoading (state, loading) {
    state.loading = loading;
  }
}

const actions = {
  async loadAll (context, { documentId, titleId }) {
    context.commit('setLoading', true);
    const response = await this._vm.$axios.get(`documents/${documentId}/titles/${titleId}/comments`);
    context.commit('setAll', response.data.data);
    context.commit('setLoading', false);
  },
  clearAll (context) {
    context.commit('setAll', []);
  },

  async createComment (context, { documentId, titleId, comment }) {
    const response = await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/comments`, comment);
    return response.headers.location.split('/').reverse()[0];
  },

  // async create (context, { documentId, titleId, commentId, message }) {
  //   await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/comments/${commentId}`, message);
  // },

  async updateComment (context, { documentId, titleId, comment }) {
    const { id, ...values } = comment;
    await this._vm.$axios.patch(`documents/${documentId}/titles/${titleId}/comments/${id}`, values);
  },

  async delete (context, { documentId, titleId, commentId }) {
    await this._vm.$axios.delete(`documents/${documentId}/titles/${titleId}/comments/${commentId}`);
  },
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
