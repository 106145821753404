<template>
  <b-container fluid class="document-view">
    <b-row>
      <b-col class="document-view-top"><TopPane/></b-col>
    </b-row>
    <b-row>
      <b-col cols="3" class="document-view-left"><LeftPane/></b-col>
      <b-col cols="6" class="document-view-center"><CenterPane/></b-col>
      <b-col cols="3" class="document-view-right"><RightPane/></b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import LeftPane from '@/views/layout/LeftPane';
import RightPane from '@/views/layout/RightPane';
import CenterPane from '@/views/layout/CenterPane';
import TopPane from '@/views/layout/TopPane';

export default {
  name: 'Document',
  async beforeRouteEnter (to, from, next) {
    next(async vm => {
      // access to component instance via `vm`
      if (to.params.documentId != from.params.documentId) {
        vm.$store.dispatch('documents/setActive', to.params.documentId);
        vm.$store.dispatch('titles/load', to.params.documentId);
        vm.$store.dispatch('versions/loadAll', to.params.documentId);
      }
      vm.$store.dispatch('comments/clearAll');
      vm.$store.dispatch('comments/loadAll', to.params);
      vm.$store.dispatch('sections/clearAll');
      vm.$store.dispatch('sections/loadAll', to.params);
      vm.$store.dispatch('resources/clearAll');
      vm.$store.dispatch('resources/loadAll', to.params.titleId);
    })
  },
  async beforeRouteUpdate (to, from, next) {
    // just use `this`
    if (to.params.documentId != from.params.documentId) {
      this.$store.dispatch('documents/setActive', to.params.documentId);
      this.$store.dispatch('titles/load', to.params.documentId);
      this.$store.dispatch('versions/loadAll', to.params.documentId);
    }
    this.$store.dispatch('comments/clearAll');
    this.$store.dispatch('comments/loadAll', to.params);
    this.$store.dispatch('sections/clearAll');
    this.$store.dispatch('sections/loadAll', to.params);
    this.$store.dispatch('resources/clearAll');
    this.$store.dispatch('resources/loadAll', to.params.titleId);
    next();
  },
  components: {
    LeftPane,
    RightPane,
    CenterPane,
    TopPane
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.document-view-top {
  border-bottom: 1px solid darkgrey;
}
.document-view-left, .document-view-center, .document-view-right {
  text-align: left;
}

.document-view-left, .document-view-right {
  padding: 0;
}
.document-view-center {
  padding-left: 0.3em;
  padding-right: 0.3em;
}

</style>
