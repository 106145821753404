
<template>
  <b-container fluid class="document-list">
    <b-row class="document-list-top">
      <b-col><TopPane/></b-col>
    </b-row>
    <b-row class="document-list-bottom">
      <b-col><DocumentManager/></b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import DocumentManager from '@/components/document/DocumentManager';
import TopPane from '@/views/layout/TopPane';

export default {
  name: 'DocumentList',
  async beforeRouteEnter (to, from, next) {
    next(async vm => {
      // access to component instance via `vm`
      vm.$store.dispatch('documents/clearActive');
      vm.$store.dispatch('titles/clear');
      vm.$store.dispatch('sections/clearAll');
      await vm.$store.dispatch('documents/load');
    })
  },
  components: {
    DocumentManager,
    TopPane
  }
}

</script>

<style scoped>
  .document-list-top {
    border-bottom: 1px solid darkgrey;
  }
  .document-list-bottom {
    background-color: white;
  }

</style>
