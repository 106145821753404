import Vue from 'vue';
import App from '@/App';
import router from '@/router'
import store from '@/store'

import VueAxios from './plugins/axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faCircle} from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { dom } from '@fortawesome/fontawesome-svg-core'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Install VueAxios
Vue.use(VueAxios);

Vue.prototype.$axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    // store.dispatch('logout')
    router.push('/login');
  }
  return Promise.reject(error)
});

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// dom.watch()
// library.add(faCircle)
// Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
