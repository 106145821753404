<template>
  <span class="resource-manager">
    <!--Modal for naming resource-->
    <b-modal :id="resourceModal.id" :title="resourceModal.title" ok-cancel @ok="saveResource()">
      <b-form-group id="input-name" label="Anna tietopaketille nimi: ">
        <b-form-input v-model="resourceModal.resource.name"></b-form-input>
      </b-form-group>
    </b-modal>

    <!--Modal for showing resource-->
    <b-modal :id="contentModal.id" :title="contentModal.title" size="lg" ok-only>
      <div class="resource">
        <div class="clearfix">
          <div class="resource-title float-left">{{active.name}}</div>
          <b-dropdown v-show="!preview" :no-caret="true" size="sm" class="resource-menu float-right" variant="primary" v-if="account.role == 'ADMIN'">
            <template #button-content>
              <b-icon-list/>
            </template>
            <b-dropdown-item @click="createContent(active, 'GENERAL')" v-if="active.type == 'COMMON'"><b-icon-plus/> Lisää yleistä</b-dropdown-item>
            <b-dropdown-item @click="createContent(active, 'DESCRIPTION')" v-if="active.type == 'PART'"><b-icon-plus/> Lisää kuvaus</b-dropdown-item>
            <b-dropdown-item @click="createContent(active, 'REQUIREMENT')" v-if="active.type == 'COMMON' || active.type == 'PART'"><b-icon-plus/> Lisää laatuvaatimukset</b-dropdown-item>
            <b-dropdown-item @click="createContent(active, 'INSTRUCTION')" v-if="active.type == 'PART'"><b-icon-plus/> Lisää työohjeet</b-dropdown-item>
            <b-dropdown-item @click="createContent(active, 'FEATURE')" v-if="active.type == 'PART'"><b-icon-plus/> Lisää ominaisuudet</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="editResource(active)"><b-icon-pencil/> Nimeä uudelleen</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="resource-contents generals" v-if="active.generals">
          <div class="resource-content general" v-for="(general, generalIdx) in active.generals" :key="general.id">
            <b-dropdown v-show="!preview" v-if="!hasUnsavedContent(general.id) && account.role == 'ADMIN'" :no-caret="true" size="sm" class="resource-content-menu">
              <template #button-content>
                <b-icon-list/>
              </template>
              <b-dropdown-item @click="moveUpContent(active, 'GENERAL', general)" v-if="generalIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
              <b-dropdown-item @click="moveDownContent(active, 'GENERAL', general)" v-if="generalIdx < active.generals.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
              <b-dropdown-item @click="editContent(active, 'GENERAL', general)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
              <b-dropdown-item @click="deleteContent(active, 'GENERAL', general)"><b-icon-trash/> Poista</b-dropdown-item>
            </b-dropdown>
            <div v-show="!hasUnsavedContent(general.id)" class="resource-content-body" v-html="general.data"></div>
            <div v-if="hasUnsavedContent(general.id)" v-show="preview" class="resource-content-body" v-html="unsaved[general.id]"></div>
            <Editor
              class="resource-content-editor"
              v-if="hasUnsavedContent(general.id)"
              v-show="!preview"
              v-model="unsaved[general.id]"
              v-on:save="saveContent(active, 'GENERAL', general)"
              v-on:cancel="cancelContent(active, 'GENERAL', general)" />
          </div>
        </div>
        <div class="resource-contents descriptions" v-if="active.descriptions">
          <div class="resource-content description" v-for="(description, descriptionIdx) in active.descriptions" :key="description.id">
            <b-dropdown v-show="!preview" v-if="!hasUnsavedContent(description.id) && account.role == 'ADMIN'" :no-caret="true" size="sm" class="resource-content-menu">
              <template #button-content>
                <b-icon-list/>
              </template>
              <b-dropdown-item @click="moveUpContent(active, 'DESCRIPTION', description)" v-if="descriptionIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
              <b-dropdown-item @click="moveDownContent(active, 'DESCRIPTION', description)" v-if="descriptionIdx < active.descriptions.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
              <b-dropdown-item @click="editContent(active, 'DESCRIPTION', description)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
              <b-dropdown-item @click="deleteContent(active, 'DESCRIPTION', description)"><b-icon-trash/> Poista</b-dropdown-item>
            </b-dropdown>
            <div v-show="!hasUnsavedContent(description.id)" class="resource-content-body" v-html="description.data"></div>
            <div v-if="hasUnsavedContent(description.id)" v-show="preview" class="resource-content-body" v-html="unsaved[description.id]"></div>
            <Editor
              class="resource-content-editor"
              v-if="hasUnsavedContent(description.id)"
              v-show="!preview"
              v-model="unsaved[description.id]"
              v-on:save="saveContent(active, 'DESCRIPTION', description)"
              v-on:cancel="cancelContent(active, 'DESCRIPTION', description)" />
          </div>
        </div>
        <div class="resource-contents requirements" v-if="active.requirements">
          <div v-if="document.language == 'SV'" class="resource-content-title">Kvalitetskrav</div>
          <div v-else class="resource-content-title">Laatuvaatimukset</div>
          <div class="resource-content requirement" v-for="(requirement, requirementIdx) in active.requirements" :key="requirement.id">
            <b-dropdown v-show="!preview" v-if="!hasUnsavedContent(requirement.id) && account.role == 'ADMIN'" :no-caret="true" size="sm" class="resource-content-menu">
              <template #button-content>
                <b-icon-list/>
              </template>
              <b-dropdown-item @click="moveUpContent(active, 'REQUIREMENT', requirement)" v-if="requirementIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
              <b-dropdown-item @click="moveDownContent(active, 'REQUIREMENT', requirement)" v-if="requirementIdx < active.requirements.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
              <b-dropdown-item @click="editContent(active, 'REQUIREMENT', requirement)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
              <b-dropdown-item @click="deleteContent(active, 'REQUIREMENT', requirement)"><b-icon-trash/> Poista</b-dropdown-item>
            </b-dropdown>
            <div v-show="!hasUnsavedContent(requirement.id)" class="resource-content-body" v-html="requirement.data"></div>
            <div v-if="hasUnsavedContent(requirement.id)" v-show="preview" class="resource-content-body" v-html="unsaved[requirement.id]"></div>
            <Editor
              class="resource-content-editor"
              v-if="hasUnsavedContent(requirement.id)"
              v-show="!preview"
              v-model="unsaved[requirement.id]"
              v-on:save="saveContent(active, 'REQUIREMENT', requirement)"
              v-on:cancel="cancelContent(active, 'REQUIREMENT', requirement)"  />
          </div>
        </div>
        <div class="resource-contents instructions" v-if="active.instructions">
          <div v-if="document.language == 'SV'" class="resource-content-title">Arbetsinstruktioner</div>
          <div v-else class="resource-content-title">Työohjeet</div>
          <div class="resource-content instruction" v-for="(instruction, instructionIdx) in active.instructions" :key="instruction.id">
            <b-dropdown v-show="!preview" v-if="!hasUnsavedContent(instruction.id) && account.role == 'ADMIN'" :no-caret="true" size="sm" class="resource-content-menu">
              <template #button-content>
                <b-icon-list/>
              </template>
              <b-dropdown-item @click="moveUpContent(active, 'INSTRUCTION', instruction)" v-if="instructionIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
              <b-dropdown-item @click="moveDownContent(active, 'INSTRUCTION', instruction)" v-if="instructionIdx < active.instructions.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
              <b-dropdown-item @click="editContent(active, 'INSTRUCTION', instruction)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
              <b-dropdown-item @click="deleteContent(active, 'INSTRUCTION', instruction)"><b-icon-trash/> Poista</b-dropdown-item>
            </b-dropdown>
            <div v-show="!hasUnsavedContent(instruction.id)" class="resource-content-body" v-html="instruction.data"></div>
            <div v-if="hasUnsavedContent(instruction.id)" v-show="preview" class="resource-content-body" v-html="unsaved[instruction.id]"></div>
            <Editor
              class="resource-content-editor"
              v-if="hasUnsavedContent(instruction.id)"
              v-show="!preview"
              v-model="unsaved[instruction.id]"
              v-on:save="saveContent(active, 'INSTRUCTION', instruction)"
              v-on:cancel="cancelContent(active, 'INSTRUCTION', instruction)" />
          </div>
        </div>
        <div class="resource-contents features" v-if="active.features">
          <div v-if="document.language == 'SV'" class="resource-content-title">Egenskaper</div>
          <div v-else class="resource-content-title">Ominaisuudet</div>
          <div class="resource-content feature" v-for="(feature, featureIdx) in active.features" :key="feature.id">
            <b-dropdown v-show="!preview" v-if="!hasUnsavedContent(feature.id) && account.role == 'ADMIN'" :no-caret="true" size="sm" class="resource-content-menu float-right">
              <template #button-content>
                <b-icon-list/>
              </template>
              <b-dropdown-item @click="moveUpContent(active, 'FEATURE', feature)" v-if="featureIdx > 0"><b-icon-arrow-up/> Siirrä ylöspäin</b-dropdown-item>
              <b-dropdown-item @click="moveDownContent(active, 'FEATURE', feature)" v-if="featureIdx < active.features.length - 1"><b-icon-arrow-down/> Siirrä alaspäin</b-dropdown-item>
              <b-dropdown-item @click="editContent(active, 'FEATURE', feature)"><b-icon-pencil/> Muokkaa</b-dropdown-item>
              <b-dropdown-item @click="deleteContent(active, 'FEATURE', feature)"><b-icon-trash/> Poista</b-dropdown-item>
            </b-dropdown>
            <div v-show="!hasUnsavedContent(feature.id)" class="resource-content-body" v-html="feature.data"></div>
            <div v-if="hasUnsavedContent(feature.id)" v-show="preview" class="resource-content-body" v-html="unsaved[feature.id]"></div>
            <Editor
              class="resource-content-editor"
              v-if="hasUnsavedContent(feature.id)"
              v-show="!preview"
              v-model="unsaved[feature.id]"
              v-on:save="saveContent(active, 'FEATURE', feature)"
              v-on:cancel="cancelContent(active, 'FEATURE', feature)" />
          </div>
        </div>
      </div>
    </b-modal>

    <div class="menu-bar hilkka-style">
      <b-dropdown size="sm" class="menu-button" v-if="account.role == 'ADMIN'">
        <template #button-content>
          <b-icon-plus /> Lisää tietopaketti
        </template>
        <b-dropdown-item @click="createResource('COMMON', 'Yleistä')">Yleistä</b-dropdown-item>
        <b-dropdown-item v-if="document.language == 'SV'" @click="createResource('PART', 'Byggnadsdel')">Rakennusosa</b-dropdown-item>
        <b-dropdown-item v-else @click="createResource('PART', 'Rakennusosa')">Rakennusosa</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="resources">

      <b-table
          striped
          show-empty
          small
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
        >

        <template #cell(name)="row">
          <div class="first-column">
            <a class="resource-name" @click="openResource(row.item)">{{row.item.name}}</a>
          </div>
        </template>
        <template #cell(actions)="row">
            <div class="buttons" align="right">
              <b-button v-b-tooltip.hover title="Tuo selostukseen" size="sm" @click="importResource(row.item)" class="mr-1">
                <b-icon-arrow-left/>
              </b-button>
              <b-button v-b-tooltip.hover title="Poista tietopaketti" size="sm" @click="deleteResource(row.item)" variant="danger" class="mr-1" v-if="account.role == 'ADMIN'">
                <b-icon-trash/>
              </b-button>
            </div>
        </template>
      </b-table>
    </div>

  </span>
</template>

<script>
import Editor from "@/components/editor/Editor";

export default {
  name: 'ResourceManager',
  data() {
    return {
      preview: false,
      unsaved: [],
      fields: [
        { key: 'name', label: 'Tietopaketti', sortable: true },
        { key: 'actions', label: '' }
      ],
      sortBy: 'name',
      contentModal: {
        id: 'resource-content-modal',
        title: 'Tietopaketti'
      },
      resourceModal: {
        id: 'resource-modal',
        title: '',
        resource: {},
        text: '',
      }
    }
  },

  computed: {
    active () {
      return this.$store.state.resources.active
    },
    items () {
      return this.$store.state.resources.all
    },
    document() {
      return this.$store.state.documents.active
    },
    account () {
      return this.$store.state.account.user
    },
  },
  methods: {
    hasUnsavedContent(id) {
      return this.unsaved[id] !== undefined;
    },
    async createContent(resource, type) {
      const payload = {
        titleId: this.$route.params.titleId,
        resourceId: resource.id,
        type: type,
        content: { data: null }
      }
      const contentId = await this.$store.dispatch('resources/createContent', payload);
      this.$set(this.unsaved, contentId, '');
      await this.$store.dispatch('resources/setActive', { titleId: this.$route.params.titleId, resource: resource });
    },
    editContent(resource, type, content) {
      this.$set(this.unsaved, content.id, content.data);
    },
    async saveContent(resource, type, content) {
      try {
        const payload = {
          titleId: this.$route.params.titleId,
          resourceId: resource.id,
          type: type,
          content: { id: content.id, data: this.unsaved[content.id] }
        };
        if (content.id) {
          await this.$store.dispatch('resources/updateContent', payload);
        } else {
          await this.$store.dispatch('resources/createContent', payload);
        }
        this.$set(this.unsaved, content.id);
        await this.$store.dispatch('resources/setActive', { titleId: this.$route.params.titleId, resource: resource });
      } catch (error) {
        this.handleError(error);
      }
    },
    async moveUpContent(resource, type, content) {
      try {
        const payload = {
          titleId: this.$route.params.titleId,
          resourceId: resource.id,
          type: type,
          contentId: content.id
        };
        await this.$store.dispatch('resources/moveUpContent', payload);
        await this.$store.dispatch('resources/setActive', { titleId: this.$route.params.titleId, resource: resource });
      } catch (error) {
        this.handleError(error);
      }
    },
    async moveDownContent(resource, type, content) {
      try {
        const payload = {
          titleId: this.$route.params.titleId,
          resourceId: resource.id,
          type: type,
          contentId: content.id
        };
        await this.$store.dispatch('resources/moveDownContent', payload);
        await this.$store.dispatch('resources/setActive', { titleId: this.$route.params.titleId, resource: resource });
      } catch (error) {
        this.handleError(error);
      }
    },
    cancelContent(resource, type, content) {
      this.$set(this.unsaved, content.id);
    },
    async deleteContent(resource, type, content) {
      const options = {
        title: 'Vahvistus',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Poista',
        cancelTitle: 'Peruuta',
      };
      const confirmed = await this.$bvModal.msgBoxConfirm(`Haluatko poistaa sisällön?`, options);
      if (confirmed) {
        try {
          if (this.unsaved[content.id]) {
            this.$set(this.unsaved, content.id);
          }
          await this.$store.dispatch('resources/deleteContent', {
            titleId: this.$route.params.titleId,
            resourceId: resource.id,
            type: type,
            contentId: content.id
          });
          await this.$store.dispatch('resources/setActive', { titleId: this.$route.params.titleId, resource: resource });
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    async createResource(type, title) {
      this.resourceModal.title = title;
      this.resourceModal.resource = { type: type, name: null };
      this.$bvModal.show(this.resourceModal.id);
    },
    async editResource(resource) {
      this.resourceModal.title = 'Nimeä uudelleen';
      this.resourceModal.resource = { id: resource.id, name: resource.name };
      this.$bvModal.show(this.resourceModal.id);
    },
    async saveResource() {
      const payload = { titleId: this.$route.params.titleId, resource: this.resourceModal.resource };
      if (payload.resource.id == null) {
        payload.resource.id = await this.$store.dispatch('resources/create', payload);
        this.openResource(payload.resource);
      } else {
        await this.$store.dispatch('resources/update', payload);
        await this.$store.dispatch('resources/setActive', payload);
      }
      this.$store.dispatch('resources/loadAll', this.$route.params.titleId);
    },
    async importResource(resource) {
      await this.$store.dispatch('sections/import', {
        documentId: this.$route.params.documentId,
        titleId: this.$route.params.titleId,
        resourceId: resource.id
      });
      this.$store.dispatch('sections/loadAll', this.$route.params);
    },
    async deleteResource(resource) {
      const options = {
        title: 'Vahvistus',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Poista',
        cancelTitle: 'Peruuta',
      };
      const confirmed = await this.$bvModal.msgBoxConfirm(`Haluatko varmasti poista "${resource.name}" tietopankista?`, options);
      if (confirmed) {
        const payload = {
          titleId: this.$route.params.titleId,
          resourceId: resource.id,
        };
        await this.$store.dispatch('resources/delete', payload);
        this.$store.dispatch('resources/loadAll', this.$route.params.titleId);
      }
    },

    async openResource(resource) {
      await this.$store.dispatch('resources/setActive', { titleId: this.$route.params.titleId, resource: resource });
      this.$bvModal.show(this.contentModal.id);
    },

    handleError(error) {
      this.$bvModal.msgBoxOk(`The request failed with status ${error.response.data.statusCode}.`);
    },
  },

  components: {
    Editor
  }
}
</script>

<style scoped>
  .resources {
    height: calc(100vh - 87px);
    background-color: white;
    overflow: auto;
  }

  .resource-name {
    color: black;
    text-decoration: none;
  }
  .resource-name:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .resource-content {
    font-family: arial;
    padding-left: 20px;
    padding-bottom: 10px;
    font-size: 14px;
    position: relative;
    min-height: 45px;
  }
  .resource-content-title {
    font-weight: bold;
    font-size: 14px;
    padding-left: 20px;
    padding-bottom: 7px;
  }
  .resource-content-menu {
    position: absolute;
    right: 0px;
  }

  .resource-title {
    font-weight: bold;
    padding-left: 20px;
    padding-right: 10px;
    padding-top:12px;
    padding-bottom:12px;
    font-size: 16px;
  }

  .menu-bar {
    text-align: left;
    padding: 0 0 6px 0;
    min-height: 43px;
  }
  .menu-bar .menu-button {
    margin: 6px 0 0 0;
  }

  .resource >>> p {
    margin-bottom: 1px;
  }
  .resource >>> blockquote {
    padding-left: 15px !important;
  }

  .buttons {
    white-space: nowrap;
 }

 .first-column {
   max-width: 200px;
   overflow: hidden;
 }


</style>
