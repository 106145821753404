<template>
  <div class="center-pane">
    <SectionManager/>
  </div>
</template>

<script>
import SectionManager from '@/components/document/SectionManager';

export default {
  name: 'CenterPane',
  components: {
    SectionManager
  },
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.center-pane {
  min-height: calc(100vh - 55px);
}
</style>
