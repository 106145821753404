<template>
  <div class="setup-password">
    <PasswordTokenForm setup/>
  </div>
</template>

<script>
// @ is an alias to /src
import PasswordTokenForm from '@/components/user/PasswordTokenForm';

export default {
  name: 'SetupPassword',
  components: {
    PasswordTokenForm
  }
}
</script>
