<template>
  <div class="top-pane hilkka-style">
    <DocumentForm ref="documentForm"/>
    <UserManager ref="userManager"/>
    <AccountManager ref="accountManager"/>
    <b-row>
      <b-col v-if="document.id" class="document-title">
        <b-button v-b-tooltip.hover title="Kaikki hankkeet" size="sm" @click="$router.push('/document')" class="mr-1">
          <b-icon-files/>
        </b-button>
        <a class="edit-document" @click="$refs.documentForm.showUpdateForm(document)">{{document.name}}</a>
      </b-col>

      <b-col class="account">
        <b-dropdown class="account-menu" size="sm" right>
          <template #button-content>
            <b-icon-person /> {{ account.firstName }} {{ account.lastName }}
          </template>
          <b-dropdown-item-button class="item" @click="$refs.userManager.showList()" v-if="account.role == 'ADMIN'"><b-icon-people /> Käyttäjien hallinta</b-dropdown-item-button>
          <b-dropdown-item-button class="item" @click="$refs.accountManager.showForm()"><b-icon-pencil /> Päivitä tietojasi</b-dropdown-item-button>
          <b-dropdown-divider  class="item"></b-dropdown-divider>
          <b-dropdown-item-button  class="item" @click="$refs.accountManager.logout()"><b-icon-door-open /> Kirjaudu ulos</b-dropdown-item-button>
        </b-dropdown>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserManager from '@/components/user/UserManager';
import AccountManager from '@/components/user/AccountManager';
import DocumentForm from '@/components/document/DocumentForm';
import { mapState } from 'vuex';

export default {
  name: 'TopPane',
  computed: mapState({
    document: state => state.documents.active,
    account: state => state.account.user
  }),
  components: {
    UserManager,
    AccountManager,
    DocumentForm
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.top-pane {
  text-align: left;
  padding: 0 0 6px 0;
}
.document-title {
  font-size: 20px;
  padding-left: 6px;
}
.edit-document {
  color: #F0F0F0;
  text-decoration: none;
  margin-left: 6px;
  margin-top: 6px;
  display:inline-block;
}
.edit-document:hover {
  text-decoration: underline;
  cursor: pointer;
}
.account {
  text-align: right;
  padding-right: 6px;
}
.account .item {
  width: 90%;
}
.account-menu {
  margin: 6px 0 0 0;
}
</style>
