<template>
  <span class="user-manager">

    <!-- List modal -->
    <b-modal size="lg" :id="list.id" :title="list.title" ok-only @hide="resetList">
      <b-container fluid>
        <div align="right">
          <b-button size="sm" @click="showForm(null)" class="mr-1">
            <b-icon-plus/>
            Uusi
          </b-button>
        </div>

        <b-table
          show-empty
          small
          stacked="md"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >

          <template #cell(actions)="row">
            <div align="right">
              <b-button v-b-tooltip.hover title="Muokkaa tietoja" size="sm" @click="showForm(row.item)" class="mr-1">
                <b-icon-pencil/>
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="Poista käyttäjä"
                size="sm"
                variant="danger"
                @click="showConfirm(row.item)"
                class="mr-1">
                <b-icon-trash/>
              </b-button>
            </div>
          </template>

        </b-table>
      </b-container>
    </b-modal>

    <!-- Form modal -->
    <b-modal :id="form.id" :title="form.title" ok-cancel @ok="submitForm" @hide="resetForm">
      <b-container fluid>
        <b-form>
          <b-form-group id="input-group-email" label="Sähköpostiosoite:" label-for="input-email">
            <b-form-input
              id="input-email"
              v-model="item.email"
              :state="fieldState('email')"
              type="email"
              required
              placeholder="Syötä sähköpostiosoite"
            ></b-form-input>
            <b-form-invalid-feedback id="input-email-feedback">{{ errors['email'] }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="input-group-firstName" label="Etunimi" label-for="input-firstName">
            <b-form-input
              id="input-firstName"
              v-model="item.firstName"
              :state="fieldState('firstName')"
              required
              placeholder="Syötä etunimi"
            ></b-form-input>
            <b-form-invalid-feedback id="input-firstName-feedback">{{ errors['firstName'] }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="input-group-lastName" label="Sukunimi:" label-for="input-lastName">
            <b-form-input
              id="input-lastName"
              v-model="item.lastName"
              :state="fieldState('lastName')"
              required
              placeholder="Syötä sukunimi"
            ></b-form-input>
            <b-form-invalid-feedback id="input-lastName-feedback">{{ errors['lastName'] }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="input-group-role" label="Rooli:" label-for="input-role">
            <b-form-select
              id="input-role"
              v-model="item.role"
              :state="fieldState('role')"
              :options="roles"
              required
            ></b-form-select>
            <b-form-invalid-feedback id="input-role-feedback">{{ errors['role'] }}</b-form-invalid-feedback>
          </b-form-group>

        </b-form>
      </b-container>
    </b-modal>

  </span>
</template>


<script>
export default {
  name: 'UserManager',
  data() {
    return {
      fields: [
        { key: 'email', label: 'Sähköpostiosoite', sortable: true },
        { key: 'name', label: 'Nimi', sortable: true, sortByFormatted: true,
          filterByFormatted: true, formatter: (value, key, item) => { return item.firstName + ' ' + item.lastName; } },
        { key: 'role', label: 'Rooli', sortable: true,
          formatter: (value) => { return this.roleLabels[value]; } },
        { key: 'createdAt', label: 'Luontipäivä', sortable: true,
          formatter: (value) => {
            const created = new Date(value);
            return created.getFullYear() + '-' + (created.getMonth() + 1) + '-' + created.getDate();
          }
        },
        { key: 'actions', label: '' }
      ],
      roleLabels: { 'USER': 'User', 'ADMIN': 'Administrator' },
      totalRows: 1,
      currentPage: 1,
      perPage: null,
      pageOptions: [25, 50, 100],
      sortBy: 'email',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      list: {
        id: 'list-modal',
        title: 'Käyttäjien hallinta',
        content: ''
      },

      item: {},
      errors: {},
      roles: [
        { value: 'USER', text: 'User' },
        { value: 'ADMIN', text: 'Administrator' }
      ],
      form: {
        id: 'form-modal',
        title: '',
        content: ''
      }
    }
  },
  computed: {
    items () {
      return this.$store.state.users.all
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        });
    }
  },
  // mounted() {
  //   // Set the initial number of items
  //   this.totalRows = this.items.length;
  // },
  methods: {
    async showList() {
      if (this.$store.state.users.all.length == 0) {
        await this.$store.dispatch('users/load');
      } else {
        this.$store.dispatch('users/load');
      }
      this.$bvModal.show(this.list.id);
    },
    resetList() {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async showForm(item) {
      if (item) {
        this.form.title = 'Muokkaa tietoja';
        this.item = await this.$store.dispatch('users/fetch', item.id);
      } else {
        this.form.title = 'Luo uusi käyttäjä';
        this.item = { email: null, firstName: null, lastName: null, role: 'USER' };
      }
      this.errors = {};
      this.$bvModal.show(this.form.id);
    },
    async submitForm() {
      try {
        if (this.item.id !== undefined) {
          await this.$store.dispatch('users/update', this.item);
        } else {
          await this.$store.dispatch('users/create', this.item);
        }
        // this.items = await this.fetchItems();
      } catch (error) {
        this.handleFormError(error);
      }
    },
    resetForm() {},

    fieldState(field) {
      return this.errors[field] === undefined ? null : false;
    },
    handleError(error) {
      this.$bvModal.msgBoxOk(`The request failed with status ${error.response.data.statusCode}.`);
    },
    handleFormError(error) {
      if (error.response.data.statusCode == 422) {
        this.$bvModal.show(this.form.id);
        this.errors = error.response.data.data;
      } else {
        this.handleError(error);
      }
    },

    async showConfirm(item) {
      const options = {
        title: 'Please Confirm',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      };
      const confirmed = await this.$bvModal.msgBoxConfirm(`Do you want do the delete the user "${item.email}"?`, options);
      if (confirmed) {
        await this.$store.dispatch('users/delete', item.id);
      }
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
