<template>
  <span class="account-manager">

    <!-- Form modal -->
    <b-modal :id="form.id" :title="form.title" ok-cancel @ok="submitForm" @hide="resetForm">
      <b-container fluid>
        <b-form>
          <b-form-group id="input-group-email" label="Sähköpostiosoite:" label-for="input-email">
            <b-form-input
              id="input-email"
              v-model="item.email"
              :state="fieldState('email')"
              type="email"
              required
              placeholder="Syötä sähköpostiosoite"
            ></b-form-input>
            <b-form-invalid-feedback id="input-email-feedback">{{ errors['email'] }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="input-group-firstName" label="Etunimi:" label-for="input-firstName">
            <b-form-input
              id="input-firstName"
              v-model="item.firstName"
              :state="fieldState('firstName')"
              required
              placeholder="Syötä etunimi"
            ></b-form-input>
            <b-form-invalid-feedback id="input-firstName-feedback">{{ errors['firstName'] }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="input-group-lastName" label="Sukunimi:" label-for="input-lastName">
            <b-form-input
              id="input-lastName"
              v-model="item.lastName"
              :state="fieldState('lastName')"
              required
              placeholder="Syötä sukunimi"
            ></b-form-input>
            <b-form-invalid-feedback id="input-lastName-feedback">{{ errors['lastName'] }}</b-form-invalid-feedback>
          </b-form-group>

        </b-form>
      </b-container>
    </b-modal>

  </span>
</template>


<script>
export default {
  name: 'AccountManager',
  data() {
    return {
      item: {},
      errors: {},
      form: {
        id: 'account-manager-modal',
        title: '',
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.account.user
    },
  },

  methods: {
    async logout() {
      try {
        await this.$store.dispatch('auth/logout');
        this.$store.dispatch('account/unload');
      } catch (error) {
        // Empty
      }
      this.$router.push('/login');
    },

    async showForm() {
      this.form.title = 'Päivitä tietojasi';
      this.item = await this.$store.dispatch('account/fetch');
      this.errors = {};
      this.$bvModal.show(this.form.id);
    },
    async submitForm() {
      try {
        const payload = {
          email: this.item.email,
          firstName: this.item.firstName,
          lastName: this.item.lastName,
        }
        await this.$store.dispatch('account/update', payload);
      } catch (error) {
        this.handleFormError(error);
      }
    },
    resetForm() {},

    fieldState(field) {
      return this.errors[field] === undefined ? null : false;
    },
    handleError(error) {
      this.$bvModal.msgBoxOk(`The request failed with status ${error.response.data.statusCode}.`);
    },
    handleFormError(error) {
      if (error.response.data.statusCode == 422) {
        this.$bvModal.show(this.form.id);
        this.errors = error.response.data.data;
      } else {
        this.handleError(error);
      }
    },
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
