<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">

        <b-button
          class="menubar__button"
          @click="$emit('save')"
          size="sm"
        >
          <b-icon-check /> Tallenna
        </b-button>

        <b-button
          class="menubar__button"
          @click="$emit('cancel')"
          size="sm"
        >
          <b-icon-x /> Peruuta
        </b-button>

        <b-button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
          v-b-tooltip.hover
          title="Bold"
          size="sm"
        >
          <b-icon-type-bold />
        </b-button>

        <b-button
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
          v-b-tooltip.hover
          title="Italic"
          size="sm"
        >
          <b-icon-type-italic />
        </b-button>

        <b-button
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
          v-b-tooltip.hover
          title="Strikethrough"
          size="sm"
        >
          <b-icon-type-strikethrough />
        </b-button>

        <b-button
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
          v-b-tooltip.hover
          title="Underline"
          size="sm"
        >
          <b-icon-type-underline />
        </b-button>

        <b-button
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
          v-b-tooltip.hover
          title="Bullet list"
          size="sm"
        >
          <b-icon-list-ul />
        </b-button>

        <b-button
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
          v-b-tooltip.hover
          title="Ordered list"
          size="sm"
        >
          <b-icon-list-ol />
        </b-button>

        <b-button
          class="menubar__button"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
          v-b-tooltip.hover
          title="Block indent"
          size="sm"
        >
          <b-icon-blockquote-left />
        </b-button>

        <b-button
          class="menubar__button"
          @click="commands.undo"
          v-b-tooltip.hover
          title="Undo"
          size="sm"
        >
          <b-icon-reply-fill flip-h/>
        </b-button>

        <b-button
          class="menubar__button"
          @click="commands.redo"
          v-b-tooltip.hover
          title="Redo"
          size="sm"
        >
          <b-icon-reply-fill/>
        </b-button>

      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  HardBreak,
  Blockquote,
  BulletList,
  OrderedList,
  ListItem,
  Image,
  Bold,
  Italic,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'
export default {
  name: "Editor",
  props: ['value'],
  components: {
    EditorContent,
    EditorMenuBar
  },
  created() {
    this.editor.setContent(this.value);
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new HardBreak(),
          new Blockquote(),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Image(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        onUpdate: ({getHTML}) => {
          this.lastValue = getHTML();
          this.$emit('input', this.lastValue)
        }
      }),
      lastValue: null
    }
  },
  watch: {
    value (newValue) {
      if (this.lastValue != newValue) {
        this.editor.setContent(newValue);
      }
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  },
}
</script>

<style scoped>
 .editor { border: 1px solid #6c757d; border-radius: 4px; }
 .menubar { background-color: #6c757d; }
 .menubar__button:nth-child(2) {
   margin-right: 0.75em;
 }
 .menubar__button:nth-child(6) {
   margin-right: 0.75em;
 }
 .menubar__button:nth-child(9) {
   margin-right: 0.75em;
 }

</style>


<style>
  .ProseMirror { outline: none; }
  .ProseMirror blockquote { margin-left: 1em; }

</style>
