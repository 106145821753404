const state = () => ({
  all: [],
  loading: false
})

const mutations = {
  setAll (state, all) {
    state.all = all;
  },
  setLoading (state, loading) {
    state.loading = loading;
  }
}

const actions = {
  async loadAll (context, { documentId, titleId }) {
    context.commit('setLoading', true);
    const response = await this._vm.$axios.get(`documents/${documentId}/titles/${titleId}/contents`);
    context.commit('setAll', response.data.data);
    context.commit('setLoading', false);
  },
  clearAll (context) {
    context.commit('setAll', []);
  },
  async create (context, { documentId, titleId, section }) {
    const response = await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/sections`, section);
    return response.headers.location.split('/').reverse()[0];
  },
  async import (context, { documentId, titleId, resourceId }) {
    const response = await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/import/${resourceId}`);
    return response.headers.location.split('/').reverse()[0];
  },
  async importInto (context, { documentId, titleId, sectionId, resourceId }) {
    const response = await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/sections/${sectionId}/import/${resourceId}`);
    return response.headers.location.split('/').reverse()[0];
  },
  async update (context, { documentId, titleId, section }) {
    const { id, ...values } = section;
    await this._vm.$axios.patch(`documents/${documentId}/titles/${titleId}/sections/${id}`, values);
  },
  async moveUp (context, { documentId, titleId, sectionId }) {
    await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/sections/${sectionId}/up`);
  },
  async moveDown (context, { documentId, titleId, sectionId }) {
    await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/sections/${sectionId}/down`);
  },
  async delete (context, { documentId, titleId, sectionId }) {
    await this._vm.$axios.delete(`documents/${documentId}/titles/${titleId}/sections/${sectionId}`);
  },
  async createContent (context, { documentId, titleId, sectionId, type, content }) {
    const response = await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/sections/${sectionId}/types/${type}`, content);
    return response.headers.location.split('/').reverse()[0];
  },
  async updateContent (context, { documentId, titleId, sectionId, type, content }) {
    const { id, ...values } = content;
    await this._vm.$axios.patch(`documents/${documentId}/titles/${titleId}/sections/${sectionId}/types/${type}/${id}`, values);
  },
  async moveUpContent (context, { documentId, titleId, sectionId, type, contentId }) {
    await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/sections/${sectionId}/types/${type}/${contentId}/up`);
  },
  async moveDownContent (context, { documentId, titleId, sectionId, type, contentId }) {
    await this._vm.$axios.post(`documents/${documentId}/titles/${titleId}/sections/${sectionId}/types/${type}/${contentId}/down`);
  },
  async deleteContent (context, { documentId, titleId, sectionId, type, contentId }) {
    await this._vm.$axios.delete(`documents/${documentId}/titles/${titleId}/sections/${sectionId}/types/${type}/${contentId}`);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
