const state = () => ({
  all: [],
  active: {}
})

const mutations = {
  setAll (state, all) {
    state.all = all;
  },
  setActive (state, active) {
    state.active = active;
  }
}

const actions = {
  async loadAll (context, titleId) {
    const response = await this._vm.$axios.get(`titles/${titleId}/resources`);
    context.commit('setAll', response.data.data);
  },
  clearAll (context) {
    context.commit('setAll', []);
  },
  async setActive (context, { titleId, resource }) {
    const response = await this._vm.$axios.get(`titles/${titleId}/resources/${resource.id}`);
    context.commit('setActive', response.data.data);
  },
  clearActive (context) {
    context.commit('setActive', {});
  },
  async create(context, { titleId, resource }) {
    const response = await this._vm.$axios.post(`titles/${titleId}/resources`, resource);
    return response.headers.location.split('/').reverse()[0];
  },
  async update(context, { titleId, resource }) {
    const { id, ...values } = resource;
    await this._vm.$axios.patch(`titles/${titleId}/resources/${id}`, values);
  },
  async delete(context, { titleId, resourceId }) {
    await this._vm.$axios.delete(`titles/${titleId}/resources/${resourceId}`);
  },
  async createContent(context, { titleId, resourceId, type, content }) {
    const response = await this._vm.$axios.post(`titles/${titleId}/resources/${resourceId}/types/${type}`, content);
    return response.headers.location.split('/').reverse()[0];
  },
  async updateContent(context, { titleId, resourceId, type, content }) {
    const { id, ...values } = content;
    await this._vm.$axios.patch(`titles/${titleId}/resources/${resourceId}/types/${type}/${id}`, values);
  },
  async moveUpContent(context, { titleId, resourceId, type, contentId }) {
    await this._vm.$axios.post(`titles/${titleId}/resources/${resourceId}/types/${type}/${contentId}/up`);
  },
  async moveDownContent(context, { titleId, resourceId, type, contentId }) {
    await this._vm.$axios.post(`titles/${titleId}/resources/${resourceId}/types/${type}/${contentId}/down`);
  },
  async deleteContent(context, { titleId, resourceId, type, contentId }) {
    await this._vm.$axios.delete(`titles/${titleId}/resources/${resourceId}/types/${type}/${contentId}`);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
