<template>
  <div class="login">
    <LoginForm/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/user/LoginForm';

export default {
  name: 'Login',
  components: {
    LoginForm
  }
}
</script>
