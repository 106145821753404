const state = () => ({
  all: []
})

const mutations = {
  setUsers (state, users) {
    state.all = users;
  }
}

// actions
const actions = {
  async load(context) {
    const users = await context.dispatch('fetchAll')
    context.commit('setUsers', users);
  },
  async fetchAll() {
    const response = await this._vm.$axios.get(`users?fields=id,email,firstName,lastName,role,createdAt`);
    return response.data.data;
  },
  async fetch(context, id) {
    const response = await this._vm.$axios.get(`users/${id}?fields=id,email,firstName,lastName,role`);
    return response.data.data;
  },
  async create(context, user) {
    await this._vm.$axios.post(`users`, user);
    await context.dispatch('load');
  },
  async update(context, user) {
    const { id, ...values } = user;
    await this._vm.$axios.patch(`users/${id}`, values);
    await context.dispatch('load');
  },
  async delete(context, id) {
    await this._vm.$axios.delete(`users/${id}`);
    await context.dispatch('load');
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
