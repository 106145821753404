const state = () => ({
  isLoggedIn: false
})

const mutations = {
  login (state) {
    state.isLoggedIn = true;
  },
  logout (state) {
    state.isLoggedIn = false;
  }
}

const actions = {
  async login(context, item) {
    await this._vm.$axios.post(`auth`, item);
    context.commit('login');
  },
  async logout(context) {
    await this._vm.$axios.post(`auth/unset`);
    context.commit('logout');
  },

  async reset(context, item) {
    await this._vm.$axios.post(`auth/reset`, item);
  },
  async fetchEmailByResetToken(context, resetToken) {
    const response = await this._vm.$axios.get(`auth/reset/${resetToken}`);
    return response.data.data.email;
  },
  async resetPassword(context, item) {
    await this._vm.$axios.post(`auth/reset/${item.resetToken}`, { password: item.password });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
