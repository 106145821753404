<template>
  <div class="reset-password">
    <PasswordTokenForm/>
  </div>
</template>

<script>
// @ is an alias to /src
import PasswordTokenForm from '@/components/user/PasswordTokenForm';

export default {
  name: 'ResetPassword',
  components: {
    PasswordTokenForm
  }
}
</script>
