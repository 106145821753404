<template>
  <div class="tree-panel">
    <v-jstree
      ref="tree"
      :data="tree"
      :show-checkbox="showCheckbox"
      :multiple="multiple"
      :allow-batch="allowBatch"
      @item-click="titleClick">
    </v-jstree>
  </div>
</template>

<script>
import VJstree from "vue-jstree";
import { mapState } from 'vuex';

export default {
  name: 'TreePanel',
  components: {
    VJstree,
  },

  mounted: function() {
    if (this.tree.length == 0 && this.titles.lenth != 0) {
      this.tree = this.buildTree(this.titles);
      if (this.multiple) {
        this.selected = this.buildSelected(this.tree);
        this.$emit('input', this.selected);
      }
    }
  },
  data: function () {
    return {
      tree: [],
      selected: [],
      status: status,
    };
  },
  computed: mapState({
    titles: state => state.titles.all
  }),
  watch: {
    titles(newValue, oldValue) {
      if (oldValue.length == 0) {
        this.tree = this.buildTree(newValue);
        if (this.multiple) {
          this.selected = this.buildSelected(this.tree);
          this.$emit('input', this.selected);
        }
      } else {
        this.updateTree(newValue, this.tree);
      }
    },
  },
  methods: {
    buildTree(titles, selected = true, level = 0) {
      let result = [];
      for (let i = 0; i < titles.length; i++) {
        let title = {
          id: titles[i].id,
          text: titles[i].text,
          icon: "title-icon title-icon-" + titles[i].status.toLowerCase(),
          opened: level < 2 ? true : false,
          selected: !this.multiple
            ? (titles[i].id == this.$route.params.titleId ? true : false)
            : (selected && titles[i].status == "COMPLETE" ? true : false)
        }
        if (titles[i].children) {
          title.children = this.buildTree(titles[i].children, selected && titles[i].status == "COMPLETE", level + 1);
        }
        result.push(title);
      }
      return result;
    },
    buildSelected(titles) {
      let selected = [];
      for (let i = 0; i < titles.length; i++) {
        if (titles[i].selected) {
          selected.push(titles[i].id);
        }
        if (titles[i].children) {
          const result = this.buildSelected(titles[i].children);
          for (let j = 0; j < result.length; j++) {
            selected.push(result[j]);
          }
        }
      }
      return selected;
    },
    updateTree(titles, target) {
      for (let i = 0; i < titles.length; i++) {
        target[i].icon = "title-icon title-icon-" + titles[i].status.toLowerCase();
        if (titles[i].children) {
          this.updateTree(titles[i].children, target[i].children);
        }
      }
    },
    findPath(titles, id, path = []) {
      for (let i = 0; i < titles.length; i++) {
        if (titles[i].id == id) {
          return path;
        }
        if (titles[i].children) {
          const cloned = [...path, titles[i]];
          const found = this.findPath(titles[i].children, id, cloned);
          if (found) {
            return found;
          }
        }
      }
      return null;
    },
    async titleClick (node) {
      if (!this.multiple) {
        if (this.$route.params.titleId != node.model.id) {
          this.$router.push({ path: `/document/${this.$route.params.documentId}/${node.model.id}` })
        } else {
          this.$store.dispatch('sections/loadAll', this.$route.params);
          this.$store.dispatch('comments/loadAll', this.$route.params);
          this.$store.dispatch('resources/loadAll', this.$route.params.titleId);
        }
      } else {
        if (node.model && node.model.selected) {
          const path = this.findPath(this.tree, node.model.id);
          if (path) {
            for (let i = 0; i < path.length; i++) {
              path[i].selected = true;
            }
          }
        }
        this.selected = this.buildSelected(this.tree);
        this.$emit('input', this.selected);
      }
    }
  },
  props: {
    showCheckbox: Boolean,
    multiple: Boolean,
    allowBatch: Boolean,
    value: Array,
  },
};
</script>


<style>
  .tree-panel {
    overflow: auto;
    background-color: white;
    padding-top: 5px;
  }

  .tree-panel .tree-checkbox-selection .tree-selected {
    background-color: white;
  }
  .tree-panel .tree-default .tree-selected { font-weight: bold; border: 1px dotted black; }
  .tree-panel .tree-default.tree-checkbox-selection .tree-selected { border: none; }
  .tree-panel .tree-default .tree-icon.title-icon {
    vertical-align: middle;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
  }
  .tree-panel .tree-wholerow-ul .tree-wholerow { z-index: 0 }
  .tree-panel .tree-default .title-icon-complete { background-color: darkgreen; }
  .tree-panel .tree-default .title-icon-partial { background-color: darkorange; }
  .tree-panel .tree-default .title-icon-missing { background-color: crimson; }
</style>
