const state = () => ({
  user: {}
})

const mutations = {
  setUser (state, user) {
    state.user = user;
  }
}

const actions = {
  async load (context, reload) {
    if (Object.keys(context.state.user).length == 0 || reload) {
      context.commit('setUser', await context.dispatch('fetch'));
    }
  },
  unload (context) {
    context.commit('setUser', {});
  },
  async update(context, account) {
    await this._vm.$axios.patch(`account`, account);
    await context.dispatch('load', true);
  },
  async fetch () {
    const response = await this._vm.$axios.get(`account`);
    return response.data.data;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
