<template>
  <b-container fluid="sm">
    <div class="login-form">
      <b-row class="vh-100" align-v="center">
        <b-col>
          <b-form>
            <b-form-group id="input-group-email" label="Email address:" label-for="input-email">
              <b-form-input
                id="input-email"
                v-model="item.auth.email"
                v-on:keyup.enter="submitAuthForm()"
                :state="fieldState(errors.auth.email)"
                type="email"
                name="email"
                required
                placeholder="Enter email"
              ></b-form-input>
              <b-form-invalid-feedback id="input-email-feedback">{{ errors.auth.email }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-password" label="Password:" label-for="input-password">
              <b-form-input
                id="input-password"
                v-model="item.auth.password"
                v-on:keyup.enter="submitAuthForm()"
                :state="fieldState(errors.auth.password)"
                type="password"
                name="password"
                required
                placeholder="Enter password"
              ></b-form-input>
              <b-form-invalid-feedback id="input-password-feedback">{{ errors.auth.password }}</b-form-invalid-feedback>
            </b-form-group>

            <b-row>
              <b-col>
                <b-button size="sm" @click="submitAuthForm()" variant="primary">Login</b-button>
              </b-col>
              <b-col align="right" class="hilkka-style">
                <b-button size="sm" @click="showResetForm()" class="mr-1">Forgotten your password?</b-button>
              </b-col>
            </b-row>

          </b-form>
        </b-col>
      </b-row>
    </div>

    <b-modal id="reset-modal" title="Password reset" ok-cancel @ok="submitResetForm()" @hide="resetResetForm()">
      <b-form>
        <b-form-group id="input-group-email" label="Email address:" label-for="input-email">
          <b-form-input
            id="input-email"
            v-model="item.reset.email"
            :state="fieldState(errors.reset.email)"
            type="email"
            required
            placeholder="Enter email"
          ></b-form-input>
          <b-form-invalid-feedback id="input-email-feedback">{{ errors.reset.email }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>

  </b-container>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      item: { auth: {}, reset: {} },
      errors: { auth: {}, reset: {} },
    }
  },
  methods: {
    fieldState(field) {
      return field === undefined ? null : false;
    },
    handleError(error) {
      this.$bvModal.msgBoxOk(`The request failed with status ${error.response.data.statusCode}.`);
    },
    handleFormError(error, name, modal) {
      if (error.response.data.statusCode == 422) {
        this.errors[name] = error.response.data.data;
        if (modal) {
          this.$bvModal.show(modal);
        }
      } else {
        this.handleError(error);
      }
    },

    async submitAuthForm() {
      try {
        await this.$store.dispatch('auth/login', this.item.auth)
        this.$router.push('/');
      } catch (error) {
        this.handleFormError(error, 'auth');
      }
    },

    async showResetForm() {
      this.item.reset = {};
      this.errors.reset = {};
      this.$bvModal.show('reset-modal');
    },
    async submitResetForm() {
      try {
        await this.$store.dispatch('auth/reset', this.item.reset);
      } catch (error) {
        this.handleFormError(error, 'reset', 'reset-modal');
      }
    },
    resetResetForm() {},
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
