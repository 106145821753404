const state = () => ({
  all: []
})

const mutations = {
  setAll (state, all) {
    state.all = all;
  }
}

const actions = {
  async loadAll (context, documentId) {
    const response = await this._vm.$axios.get(`documents/${documentId}/access`);
    context.commit('setAll', response.data.data);
  },
  clearAll (context) {
    context.commit('setAll', []);
  },
  async create(context, { documentId, userId }) {
    await this._vm.$axios.put(`documents/${documentId}/access/${userId}`);
  },
  async delete (context, { documentId, userId }) {
    await this._vm.$axios.delete(`documents/${documentId}/access/${userId}`);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
