const state = () => ({
  all: []
})

const mutations = {
  setAll (state, all) {
    state.all = all;
  }
}

const actions = {
  async loadAll (context, documentId) {
    const response = await this._vm.$axios.get(`documents/${documentId}/versions`);
    context.commit('setAll', response.data.data);
  },
  clearAll (context) {
    context.commit('setAll', []);
  },
  async download (context, { documentId, versionId }) {
    const response = await this._vm.$axios.get(
      `documents/${documentId}/versions/${versionId}`,
      { responseType: 'arraybuffer' }
    );
    return response.data;
  },
  async delete (context, { documentId, versionId }) {
    await this._vm.$axios.delete(`documents/${documentId}/versions/${versionId}`);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
