<template>
  <div class="document-access-list">
    <b-modal size=sm :id="modal.id" :title="modal.title" ok-only>
      <b-container fluid>
        <div align="left" v-for="(user) in users" :key="user.id">
          <b-form-checkbox
            :checked="access.includes(user.id)"
            @change="toggleAccess(user)"
            switch
          >
            {{user.firstName}} {{user.lastName}}
          </b-form-checkbox>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'DocumentAccessList',
  data() {
     return {
       documentId: null,
       modal: {
         id: 'document-access-list-modal',
         title: '',
       },
     }
  },
  computed: {
    users () {
      const users = this.$store.state.users.all;
      return users.filter(function (user) {
        return user.role == 'USER';
      });
    },
    access () {
      return this.$store.state.access.all;
    }
  },
  mounted () {
    this.modal.id = this.modal.id + '_' + this._uid
  },
  methods: {

    async showAccessList(document) {
      this.documentId = document.id;
      this.modal.title = 'Access List';
      if (this.$store.state.users.all.length == 0) {
        await this.$store.dispatch('users/load');
      } else {
        this.$store.dispatch('users/load');
      }
      await this.$store.dispatch('access/loadAll', document.id);
      this.$bvModal.show(this.modal.id);
    },

    async toggleAccess(user) {
      if (this.access.includes(user.id)) {
        this.deleteAccess(user);
      } else {
        this.createAccess(user);
      }
    },

    async createAccess(user) {
      try {
        await this.$store.dispatch('access/create', { documentId: this.documentId, userId: user.id });
        await this.$store.dispatch('access/loadAll', this.documentId);
      } catch (error) {
        this.handleError(error);
      }
    },

    async deleteAccess(user) {
      try {
        await this.$store.dispatch('access/delete', { documentId: this.documentId, userId: user.id });
        await this.$store.dispatch('access/loadAll', this.documentId);
      } catch (error) {
        this.handleError(error);
      }
    },

    handleError(error) {
      this.$bvModal.msgBoxOk(`The request failed with status ${error.response.data.statusCode}.`);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#document-image{
  margin-top: 10px;

}
</style>
