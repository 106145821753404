<template>
  <div class="left-pane">
    <div class="menu-bar hilkka-style">
      <ExportManager/>
    </div>
    <TreePanel />
  </div>
</template>

<script>

import ExportManager from '@/components/export/ExportManager.vue';
import TreePanel from '@/components/document/TreePanel.vue';

export default {
  name: 'LeftPane',
  components: {
    ExportManager,
    TreePanel
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.menu-bar {
  text-align: left;
  padding: 0 0 6px 0;
  min-height: 43px;
}
.menu-bar >>> button {
  margin: 6px 0 0 6px;
}

.left-pane {
  width: 100%;
  text-align: right;
}
.tree-panel {
  height: calc(100vh - 87px);
}



</style>
