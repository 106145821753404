<template>
  <div class="document-manager">
    <DocumentForm ref="form"/>
    <DocumentAccessList ref="accessList"/>
    <b-container fluid>
      <b-tabs>
        <b-tab title="Hankkeet">
          <div class="document-table">
          <!-- Document Table -->
            <b-table
              striped
              show-empty
              small
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :currentPage="currentPage"
           >
             <template v-slot:cell(name)="data">
                <router-link :to="`document/${data.item.id}/1`" class="document-link">{{data.item.name}}</router-link>
              </template>

                <template #head(actions)>
                  <div align="right">
                    <b-button class="newDocumentBtn" v-b-modal.modal-document size="sm" @click="$refs.form.showCreateForm()">
                      <b-icon-plus/>Uusi
                    </b-button>
                  </div>
                </template>
              <template #cell(actions)="row">
                <div align="right">
                  <b-button v-b-tooltip.hover title="Muokkaa tietoja" size="sm" @click="$refs.form.showUpdateForm(row.item)" class="mr-1">
                    <b-icon-pencil/>
                  </b-button>
                  <b-button v-b-tooltip.hover title="Kopioi hanke" size="sm" @click="$refs.form.showCopyForm(row.item)" class="mr-1">
                    <b-icon-file-earmark-plus/>
                  </b-button>
                  <b-button v-b-tooltip.hover title="Käyttöoikeus" size="sm" @click="$refs.accessList.showAccessList(row.item)" v-if="account.role == 'ADMIN'" class="mr-1">
                    <b-icon-people/>
                  </b-button>
                  <b-button v-b-tooltip.hover title="Poista hanke" size="sm" variant="danger" @click="showConfirm(row.item)" v-if="account.role == 'ADMIN'" class="mr-1">
                    <b-icon-trash/>
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
        </b-tab>
        <b-tab title="Arkisto">
          <div class="document-archive-table">

          <!-- Archive Table -->
            <b-table
              striped
              show-empty
              small
              :items="archivedItems"
              :fields="fields"
              :per-page="perPage"
              :currentPage="currentPage"
           >
             <template v-slot:cell(name)="data">
                <router-link :to="`document/${data.item.id}/1`" class="document-link">{{data.item.name}}</router-link>
              </template>
              <template #cell(actions)="row">
                <div align="right">
                  <b-button v-b-tooltip.hover title="Muokkaa tietoja" size="sm" @click="$refs.form.showUpdateForm(row.item)" class="mr-1">
                    <b-icon-pencil/>
                  </b-button>
                  <b-button v-b-tooltip.hover title="Kopioi hanke" size="sm" @click="$refs.form.showCopyForm(row.item)" class="mr-1">
                    <b-icon-file-earmark-plus/>
                  </b-button>
                  <!-- <b-button v-b-tooltip.hover title="Access list" size="sm" @click="$refs.accessList.showAccessList(row.item)" v-if="account.role == 'ADMIN'" class="mr-1">
                    <b-icon-people/>
                  </b-button> -->
                  <b-button v-b-tooltip.hover title="Käyttöoikeus" size="sm" @click="$refs.accessList.showAccessList(row.item)" v-if="account.role == 'ADMIN'" class="mr-1">
                    <b-icon-people/>
                  </b-button>
                  <b-button v-b-tooltip.hover title="Poista hanke" size="sm" variant="danger" @click="showConfirm(row.item)" v-if="account.role == 'ADMIN'" class="mr-1">
                    <b-icon-trash/>
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
        </b-tab>
      </b-tabs>
    </b-container>

  </div>
</template>

<script>
import DocumentForm from '@/components/document/DocumentForm.vue';
import DocumentAccessList from '@/components/document/DocumentAccessList.vue';

export default {
  name: 'DocumentManager',
  data() {
     return {
       fields: [
         { key: 'name', label: 'Hankkeen nimi' },
         { key: 'externalId', label: 'Numero', sortable: true },
         { key: 'description', label: 'Kuvaus'},
         { key: 'client', label: 'Tilaaja' },
         { key: 'createdAt', label: "Luontipäivä",
           formatter: (value) => {
             const created = new Date(value);
             return created.getFullYear() + '-' + (created.getMonth() + 1) + '-' + created.getDate();
           }
         },
         { key: 'actions', label: '' }
       ],
       sortBy: 'externalId',
       perPage: null,
       currentPage: 1,
     }
   },
   computed: {
     items () {
       const items = this.$store.state.documents.all;
       return items.filter(function (e) {
         return e.status == 'WIP';
       });
     },
     account () {
       return this.$store.state.account.user
     },

     archivedItems () {
       const items = this.$store.state.documents.all;
       return items.filter(function (e) {
         return e.status == 'DONE';
       });
     }
   },
   components: {
     DocumentForm,
     DocumentAccessList,
   },
   methods: {

     async showConfirm(item) {
       const options = {
         title: 'Vahvistus',
         size: 'sm',
         okVariant: 'danger',
         okTitle: 'Poista',
         cancelTitle: 'Peruuta',
       };
       const confirmed = await this.$bvModal.msgBoxConfirm(`Haluatko varmasti poista "${item.name}"?`, options);
       if (confirmed) {
         await this.$store.dispatch('documents/delete', item.id);
       }
     },
  }

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.document-manager {
  margin-top: 15px;
  height: calc(100vh - 70px);
  overflow: auto;
}

h3 {
  padding-left: 20px;
  padding-top: 15px;
}

h5 {
  padding: 10px;
}

a {
  color: #42b983;
}
.document-link {
  color:black;
}

#document-image{
  margin-top: 10px;

}

</style>
